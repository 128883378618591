import {AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT} from '../action-types'


const initialState = {}


const AuthReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case AUTH_LOGIN:
            const {id, username, name, rol} = action.payload
            return { id, username, name, rol }
        case AUTH_LOGOUT:
            window.localStorage.removeItem('token-auth')
            return {}

        default:
            return state
        break;
    }
}


export default AuthReducer