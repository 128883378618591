export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_CHECK = 'AUTH_CHECK'


export const MESSSAGE_SYSTEM_PUSH = 'MESSSAGE_SYSTEM_PUSH'

export const MESSAGE_SYSTEM_CLEAR = 'MESSAGE_SYSTEM_CLEAR'



export const RACECOURSE_SET_LIST = 'RACECOURSE_SET_LIST'

export const RACECOURSE_SET_ITEM = 'RACECOURSE_SET_ITEM'

export const RACECOURSE_SET_LIST_ALL = 'RACECOURSE_SET_LIST_ALL'
export const RACECOURSE_UPDATE_ITEM_IN_LIST_ALL = 'RACECOURSE_UPDATE_ITEM_IN_LIST_ALL'



export const PROVIDER_RACECOURSE_DAY_LIST = 'PROVIDER_RACECOURSE_DAY_LIST'

//para las jornadas
export const RACECOURSE_EVENT_SET_LIST_BY_DATE = 'RACECOURSE_EVENT_SET_LIST_BY_DATE'
export const RACECOURSE_EVENT_SET_STATUS = 'RACECOURSE_EVENT_SET_STATUS'

export const RACECOURSE_EVENT_RACE_SET_LIST = 'RACECOURSE_EVENT_RACE_SET_LIST'
export const RACECOURS_EVENT_RACE_BY_DATE_SET_LIST = 'RACECOURS_EVENT_RACE_BY_DATE_SET_LIST'

export const RACECOURSE_EVENT_RACE_UPDATE_ITEM  = 'RACECOURSE_EVENT_RACE_UPDATE_ITEM'

//para las carreras
export const RACECOURSE_EVENT_RACE_DATE_GET_LIST = 'RACECOURSE_EVENT_RACE_DATE_GET_LIST'
export const RACECOURSE_EVENT_RACE_UPDATE_STATUS = 'RACECOURSE_EVENT_RACE_UPDATE_STATUS';
export const RACECOURSE_EVENT_RACE_RUNNER_UPDATE_STATUS = 'RACECOURSE_EVENT_RACE_RUNNER_UPDATE_STATUS'

export const RACECOURSE_EVENT_RACE_RESULT_SET_LIST = 'RACECOURSE_EVENT_RACE_RESULT_SET_LIST'
export const RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS = 'RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS'
export const RACECOURSE_EVENT_RACE_RESULT_ADD_ITEM = 'RACECOURSE_EVENT_RACE_RESULT_ADD_ITEM'


export const BLACK_ITEM_TODAY_SET_LIST = 'BLACK_ITEM_TODAY_SET_LIST'
export const BLACK_ITEM_TODAY_ADD_LIST_ITEMS = 'BLACK_ITEM_TODAY_ADD_LIST_ITEMS'
export const BLACK_ITEM_TODAY_ADD_ITEM = 'BLACK_ITEM_TODAY_ADD_ITEM'