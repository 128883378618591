import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Select,
  TimePicker,
} from "antd";
import { useState } from "react";
import { IBetRunnerInput, IRaceInput, IRaceInputConfig } from "../../services/racecourse-event-create.service";
import CreateRunnerList from "./CreateRunnerList";
import { CheckSquareOutlined , ClockCircleOutlined,PlayCircleOutlined , CheckCircleOutlined, LockOutlined,WarningOutlined} from '@ant-design/icons';
import moment, { Moment } from "moment-timezone";
import { DEFAULT_TIMEZONE } from "../../config";
import { BET_TYPES_CORE } from "../../services/result.service";
type prop = {
  dateSelected: string,
  race: IRaceInputConfig,
  dispatchRaceChange: (race: IRaceInputConfig) => void
};

export const CardCreateRace = ({ dispatchRaceChange,  race, dateSelected }: prop) => {
  const [runnerNumbers, setRunnerNumbers] = useState<number>(1);

  const options = BET_TYPES_CORE.map((item) => ({label: item.name_front, value: item.bet_id}))
  const selectRunnerNumbersOptions = Array.from(
    { length: 30 },
    (_, i) => i + 1
  );
  const formatTime = 'h:mm a';
  const handleRunnerChange= (raceNumber: number, runners: IBetRunnerInput[]) => {

    console.log(runners);
    race.runners = runners;
    
    dispatchRaceChange({...race})
  }

  const handleStatusChange = (done: boolean) => {
    race.done = done;
    dispatchRaceChange({...race});
  }

  const handlePosTimeChange = (value: Moment | null, dateString: string) => {
    const fullDate = dateSelected.split('T')[0] + ' ' + dateString;
    const fullFormat = 'YYYY-MM-DD ' + formatTime;
    race.post_time = moment.tz(fullDate, fullFormat, DEFAULT_TIMEZONE).toDate().getTime();
    race.minutes_to_start = moment.tz(fullDate, fullFormat, DEFAULT_TIMEZONE).diff(moment.tz(DEFAULT_TIMEZONE), 'minutes');

    console.log(value);
    console.log(dateString);
    dispatchRaceChange({...race});
  };

  const cardStyle = race.done ? { borderColor: '#52c41a', borderWidth: '2px', width: '100%' } : { borderColor: '#faad14', borderWidth: '2px', width: '100%' };
  const cardTitle = (
    <div onClick={() => handleStatusChange(!race.done)}>
      {`Carrera ${race.number}`}
    </div>
  );

  const handleBetTypesChange = (value: any[])=> {
    race.bet_types = BET_TYPES_CORE.filter(item => value.includes(item.bet_id));
    dispatchRaceChange({...race});
  }


  return (
    <>
      <Card  style={cardStyle} title={cardTitle}
      extra={ <Checkbox type="checkbox" onChange={()=> handleStatusChange(!race.done) } checked={race.done} > Listo </Checkbox> }
      
      >

        <Form layout="vertical">

          <Form.Item label="Hora de la Carrera">
            <TimePicker disabled={race.done} onChange={handlePosTimeChange} format={formatTime}></TimePicker>  
          </Form.Item>

          <Form.Item label="Juegos">
            <Checkbox.Group disabled={race.done} options={options} onChange={handleBetTypesChange} defaultValue={race.bet_types.map(item => item.bet_id)}></Checkbox.Group>   
          </Form.Item>

          <Form.Item label="Numero de Corredores">
            <Select disabled={race.done} value={runnerNumbers} onChange={(event)=> setRunnerNumbers(event) }>
              {selectRunnerNumbersOptions.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>

        <CreateRunnerList locked={race.done} raceNumber={race.number} dispatchChanges={handleRunnerChange}  runnerCount={runnerNumbers}></CreateRunnerList>
      </Card>
    </>
  );
};
