import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Checkbox, Select } from "antd";
import { IBetRunnerInput } from "../../services/racecourse-event-create.service";
import { OPTIONS_ODDS } from "../../config";

interface Runner {
  key: string;
  number: number;
  name: string;
  odds: string;
  scratched: boolean;
}

interface Props {
  raceNumber: number;
  runnerCount: number;
  locked: boolean;
  dispatchChanges: (raceNumber: number, runners: IBetRunnerInput[]) => void;
}

const CreateRunnerList: React.FC<Props> = ({
  raceNumber,
  runnerCount,
  dispatchChanges,
  locked,
}) => {
  const [runners, setRunners] = useState<Runner[]>([]);

  const optionsRacecourseType = Object.values(OPTIONS_ODDS).map(item => {
    return (
        <Select.Option key={item} value={item}>{item}</Select.Option>
    )
  })



  useEffect(() => {
    const newRunners = new Array(runnerCount).fill(null).map((_, index) => {
      if(runners[index]) return runners[index];
      
      return {
        key: `${index + 1}`,
        number: index + 1,
        name: `EJEMPLAR ${index + 1}`,
        odds: "99",
        scratched: false,
      }
    });
    setRunners(newRunners);
    dispatchChanges(raceNumber, newRunners.map(({key,...item}) => item));
  }, [runnerCount]);

  const columns = [
    {
      title: "Number",
      dataIndex: "key",
      key: "number",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Runner) => (
        <Input
          disabled={locked}
          value={text}
          onChange={(e) => handleChange(record.key, "name", e.target.value)}
        />
      ),
    },
    {
      title: "Odds",
      dataIndex: "odds",
      key: "odds",
      render: (odds: number, record: Runner) => (
        <Select size="large" value={odds} disabled={locked} onChange={(e) => handleChange(record.key, "odds", e.toString())}>
          {optionsRacecourseType}
        </Select>
      ),
    },
    {
      title: "Retirado",
      dataIndex: "scratched",
      key: "scratched",
      render: (scratched: boolean, record: Runner) => (
        <Checkbox
          disabled={locked}
          type="checkbox"
          checked={scratched}
          onChange={(event) =>
            handleChange(record.key, "scratched", event.target.checked)
          }
        />
      ),
    },
  ];

  const handleChange = (
    key: string,
    field: keyof Runner,
    value: string | number | boolean
  ) => {
    const newData = runners.map((runner) => {
      if (runner.key === key) {
        return { ...runner, [field]: value };
      }
      return runner;
    });
    setRunners(newData);
    dispatchChanges(raceNumber, newData.map(({key,...item}) => item));
  };

  return <Table pagination={false} dataSource={runners} columns={columns} />;
};

export default CreateRunnerList;
