import { Button, Card, Select, Form } from "antd"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { messageSystemPush } from "../redux/actions/message-system"
import { racecourseSetItem, racecourseUpdateItemInListAll } from "../redux/actions/racecourse"
import { ProviderRacecourseItem } from "../services/provider-racecourse.service"
import { createRacecourse, linkRacecourseProvider, RacecourseItem, RACECOURSE_CLASS, RACECOURSE_TYPE } from "../services/racecourse.service"
import { Popconfirm, message } from 'antd';



export const ProviderRacecoursePanelToLink = ({providerRacecourseItem, racecourseListNoLinked}: {providerRacecourseItem: ProviderRacecourseItem, racecourseListNoLinked: RacecourseItem[]} ) => {

    const [loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const optionsRacecourseNotLinked = racecourseListNoLinked.map(item => {
        return (
            <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
        )
    })

    const onFinish = ({racecourseId, ...others}: any) => {
        console.log('other', others)
        console.log(racecourseId)
        linkRacecourseToProvider(racecourseId)
    }


    const linkRacecourseToProvider = async (racecourseId: string) => {
        try {
            setLoading(true)
            const response = await linkRacecourseProvider(racecourseId, {...providerRacecourseItem})
            dispatch(racecourseUpdateItemInListAll(response))
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }

    }


    const handleAddNewRacecourse = async () => {
        try {
            setLoading(true)
            
            const racecourse_class = RACECOURSE_CLASS.NO_CLASS
            const type = RACECOURSE_TYPE.AMERICAN
            const racecourse = await createRacecourse({racecourse_class, type, name: providerRacecourseItem.name})
            dispatch(racecourseSetItem(racecourse))
            const response = await linkRacecourseProvider(racecourse._id, {...providerRacecourseItem})
            dispatch(racecourseUpdateItemInListAll(response))
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }
    }




    return (<>
        <Card title={providerRacecourseItem.name} >
            <p>{providerRacecourseItem.code} {providerRacecourseItem.code_alt}</p>
            <Form
               name={'name-' + providerRacecourseItem.name}
               onFinish={onFinish}
            >
                <Form.Item name="racecourseId">
                <Select placeholder="Select and Hipodromo"> {optionsRacecourseNotLinked} </Select>
                </Form.Item>

                <Button type="primary" htmlType="submit">Vincular</Button>

                <Popconfirm
                    title="Confirma agregar nuevo hipodromo"
                    onConfirm={handleAddNewRacecourse}
                    okText="Si"
                    cancelText="No"
                >

                <Button  type="primary">Agregar Nuevo</Button>
                </Popconfirm>
            </Form>

        </Card>
    </>)
}