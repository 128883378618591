import connector from './connector.service'
import { IRacecourseEventComplete } from './interfaces/racecourse-event-race-complete';

export const RACECOURSE_EVENT_STATUS = {
    CLOSED: 'closed',
    ACTIVE: 'active',
    LOCKED: 'locked',
    FINISHED: 'finished',
    ERASED: 'erased'
}


export interface IRacecourseEvent {
    status: string;
    provider_racecourse_events: string[];
    _id: string;
    date: string;
    racecourse: Racecourse;
    created_at: string;
    updated_at: string;
    last_update: string;
  }

export  interface Racecourse {
    status: string;
    _id: string;
    owner: string;
    name: string;
    racecourse_class: string;
    type: string;
    providers: Provider[];
    created_at: string;
    updated_at: string;
  }
  
export  interface Provider {
    _id: string;
    provider: string;
    code: string;
    code_alt: string;
    name: string;
    updated_at: string;
    created_at: string;
  }

export interface IRacecourseEventChangeStatusResponse {
    status: string;
    provider_racecourse_events: string[];
    _id: string;
    date: string;
    racecourse: string;
    created_at: string;
    updated_at: string;
  }


export const getRacecourseEventsByDate = async (date: string): Promise<IRacecourseEvent[]> => {
    const races:IRacecourseEvent[] = await connector.get(`/racecourse-event/list/by-date/${date}`)
    return races.map(item => ({...item, last_update: new Date().toLocaleTimeString()}))
}


export const updateRacecourseEventStatusById = async (id:string, status: string):Promise<IRacecourseEventChangeStatusResponse> => {
    //update-status/:id/:status
    const race:IRacecourseEventChangeStatusResponse = await connector.put(`/racecourse-event/update-status/${id}/${status}`, {})
    return {...race}
}

export const getRacecourseEventCompleteById = async (id:string):Promise<IRacecourseEventComplete> => {
  const event: IRacecourseEventComplete = await connector.get(`/racecourse-event/${id}`)
  return event;
}