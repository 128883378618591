import connector from './connector.service'
import { PaginationDataResponse } from './interfaces/pagination.response.interface'

export const RACECOURSE_STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
}

export const RACECOURSE_TYPE = {
    AMERICAN: 'american',
    VENEZOLOANO: 'venezolano',
}

export const RACECOURSE_CLASS = {
    CLASS_A: 'class-a',
    CLASS_B: 'class-b',
    CLASS_C: 'class-c',
    CLASS_D: 'class-d',
    NO_CLASS: 'no-class'
}

export const RACECOURSE_PROVIDERS = {
    PROVIDER_A: 'provider-a',
    PROVIDER_B: 'provider-b',
    PROVIDER_C: 'provider-c',
    PROVIDER_D: 'provider-d'
}

export const createRacecourse = async (payload: {name: string, racecourse_class: string, type: string}):Promise<RacecourseItem> => {
    const racecourse = await connector.post(`/racecourse`, payload)
    return racecourse
}


export const getRacecourseList = async ():Promise<RacecourseItem[]> => {


    const racecourseList:RacecourseItem[] = await connector.get(`/racecourse`)
    return racecourseList;
    //const  = response.data
   //return {id, name, username, rol}
}


export const getRacecourseListFiltered = async (filter?: string):Promise<RacecourseItem[]> => {
    const query = filter ? `?filter=${filter}` : ''
    const racecourseList:RacecourseItem[] = await connector.get(`/racecourse${query}`)


    return racecourseList;
    //const  = response.data
   //return {id, name, username, rol}
}


export const getRacecourseListAll= async ():Promise<RacecourseItem[]> => {
    const racecourse:RacecourseItem[] = await connector.get(`/racecourse/list/all`)
    return racecourse
}



export const getRacecourseItem = async (id: string):Promise<RacecourseItem> => {
    const racecourse:RacecourseItem = await connector.get(`/racecourse/${id}`)

    return racecourse
}


export const linkRacecourseProvider =  async (id: string, payload: {provider:string, code: string, code_alt: string, name: string}):Promise<RacecourseItem> => {
    const racecourse:RacecourseItem = await connector.put(`/racecourse/link/${id}`, payload)

    return racecourse
}

export const unlinkRacecourseProvider =  async (id: string, payload: {provider:string}):Promise<RacecourseItem> => {
    const racecourse:RacecourseItem = await connector.put(`/racecourse/unlink/${id}`, payload)

    return racecourse
}


export const editRacecourse = async (id: string, payload: {name: string, racecourse_class: string, type: string, status: string}):Promise<RacecourseItem> => {
    const racecourse:RacecourseItem = await connector.put(`/racecourse/${id}`, payload)
    return racecourse
}



export interface RacecourseItem {
    _id: string,
    owner: string,
    name:  string,
    providers: [
        {
            provider: string,
            name: string,
            code: string,
            code_alt: string,
            updated_at: string,
            created_at: string
        }
    ],
    racecourse_class: string,
    type: string,
    status: string,
    created_at: string,
    updated_at: string
}


export interface RacecourseList {
    docs: RacecourseItem [],
    totalDocs: number,
    limit: number,
    totalPages: number,
    page: number,
    pagingCounter: number,
    hasPrevPage: boolean,
    hasNextPage: boolean,
    prevPage: number | null,
    nextPage: number | null,
}