import {MESSAGE_SYSTEM_CLEAR, MESSSAGE_SYSTEM_PUSH} from '../action-types'


const initialState = {
    statusCode: 0,
    message: '',
    error: ''
}


const MessageSystemReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case MESSSAGE_SYSTEM_PUSH:
            const {statusCode, message, error} = action.payload
            return {
                statusCode, message, error
            }
        
        case MESSAGE_SYSTEM_CLEAR:
            return {
                statusCode: 0, 
                message: '',
                error: ''
            }
 
        default:
            return state
        break;
    }
}


export default MessageSystemReducer