import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './App.css';
import { RouterApp } from './Router';

import {Provider, useSelector} from 'react-redux'
import { store } from './redux/store';


const App = () => {
  return <>
    <Provider store={store}>
      <RouterApp></RouterApp>
    </Provider>

  </>
}

export default App;
