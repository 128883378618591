import { Card, List, Table } from "antd"
import { useEffect, useState } from "react"
import { RaceInfoStatus, createResultsFromProvider, deleteResults } from "../services/racecourse-race.service"
import { ResultItem, Betresult, BET_TYPES_CORE } from "../services/result.service"
import { IProviderResult, IRacecourseRaceProviderResult } from '../services/interfaces/racecourse-race-result-provider-pending';
import { SubResultItemProviderPendingList } from "./SubResultItemProviderPendingList";

type Props = {
  raceData: IRacecourseRaceProviderResult
  handleCreateFromProvider(id: string): void
  handleDelete(id: string): void
  isLoading: boolean
}


export const ResultItemProviderPendingList = ({ raceData, handleCreateFromProvider, handleDelete, isLoading }: Props) => {

    const calculateTimeLeft = (timeA: number, timeB: number) => {
        let inverse = false
        let  difference = (timeA-  timeB)
        if(timeA < timeB)  {
            inverse = true
            difference =  timeB - timeA
        }


        
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((difference / 1000 / 60) % 60)
        const seconds = Math.floor((difference / 1000) % 60)
        
        const displayHour = hours < 10 ? '0'+ hours : hours + ''
        const displayMinutes = minutes < 10 ? '0'+ minutes : minutes + ''
        const displaySeconds = seconds < 10 ? '0'+ seconds : seconds + ''
        if(inverse) {
            return <span style={{fontWeight:'bold', color: 'red'}}> -{`${displayHour}:${displayMinutes}:${displaySeconds}`} </span>
        } else {
            return <span> {`${displayHour}:${displayMinutes}:${displaySeconds}`} </span>
        }
    }
    const gridConfig = {
        gutter: 16,
        column: 2,
    }



    

    return <>

        <List.Item>
            <Card style={{ width: '100%' }} title={`${raceData.racecourse_event.racecourse.name.toUpperCase()} - CARRERA: ${raceData.number}`}>
                <p style={{margin: '0'}}>Hora de Estimada de Cierre {new Date(raceData.post_time).toLocaleTimeString()}</p>
                <p style={{margin: '0'}}>Hora de cierre {new Date(raceData.date_close_finished).toLocaleTimeString()}</p>
                <p style={{margin: '0'}}>Diferencia entre cierre programado y cierre efectivo: {calculateTimeLeft(raceData.post_time, +new Date(raceData.date_close_finished))}</p> 
                { raceData.provider_results.length > 0 ?
                <List
                grid={gridConfig}
                itemLayout="vertical"
                dataSource={raceData.provider_results}
                key={raceData._id}
                renderItem={(item: IProviderResult) => (
                    <SubResultItemProviderPendingList 
                        handleDelete={handleDelete} 
                        isLoading={isLoading} 
                        handleCreateFromProvider={handleCreateFromProvider} 
                        key={item._id} result={item}></SubResultItemProviderPendingList>
                )}
                /> : null}
            </Card>

        </List.Item>
    </>
}