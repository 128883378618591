import { EXACTA, PLACE, SHOW, SUPERFECTA, SUPERHIGHFIVE, TRIFECTA, WIN } from "../config";
import { BetResultItem } from "../interfaces/bet-result-item";
import { BetTypeCombinationOptions } from "../interfaces/bet-types-combination-options.interface";

const createNewDataItem = (betId: number, nameFront: string, combination: string, countIndex: number, waggerAmount: number) => {
  return {
      key: new Date().getTime().toString() + countIndex,
      bet_id: betId,
      name_front: nameFront,
      combinations: combination,
      wager_amount: waggerAmount,
      payout_amount: 0,
  };
}



export const getDataByCombinations = (positionSetter: {position: number, number: number}[], betTypeCombinationOptions: BetTypeCombinationOptions[] ) => {
  const positionList1 = positionSetter.filter((position) => position.position === 1);
  const positionList2 = positionSetter.filter((position) => position.position === 2);
  const positionList3 = positionSetter.filter((position) => position.position === 3);
  const positionList4 = positionSetter.filter((position) => position.position === 4);
  const positionList5 = positionSetter.filter((position) => position.position === 5);

  const combinationsMap: { [key: string]: {position: number, number: number}[][] } = {
    [WIN]: [positionList1],
    [PLACE]: [positionList1, positionList2],
    [SHOW]: [positionList1, positionList2, positionList3],
  };
  const newData:BetResultItem[] = [];
  let countIndex = 0;

  betTypeCombinationOptions.forEach((item) => {

    const combination = combinationsMap[item.bet_id];

    if(combination) {
      combination.forEach((positionList) => {
        positionList.forEach((position) => {
            newData.push(createNewDataItem(item.bet_id, item.name_front, position.number.toString(), countIndex, 2 ));
            countIndex++;
        });
    });
    } else if(item.bet_id === EXACTA)  {
      positionList1.forEach((position1) => {
        positionList2.forEach((position2) => {
          if(position1.number !== position2.number) {
            newData.push({
              key: new Date().getTime().toString() + countIndex,
              bet_id: item.bet_id,
              name_front: item.name_front,
              combinations: `${position1.number}-${position2.number}`,
              wager_amount: 0,
              payout_amount: 0,
            })
            countIndex++;
          }   
        })
      })
    } else if (item.bet_id === TRIFECTA) {
      positionList1.forEach((position1) => {
        positionList2.forEach((position2) => {
          positionList3.forEach((position3) => {
            if(position1.number !== position2.number && position1.number !== position3.number && position2.number !== position3.number) {
              newData.push({
                key: new Date().getTime().toString() + countIndex,
                bet_id: item.bet_id,
                name_front: item.name_front,
                combinations: `${position1.number}-${position2.number}-${position3.number}`,
                wager_amount: 0,
                payout_amount: 0,
              })
              countIndex++;
            }   
          })
        })
      })
    } else if(item.bet_id === SUPERFECTA) {
      positionList1.forEach((position1) => {
        positionList2.forEach((position2) => {
          positionList3.forEach((position3) => {
            positionList4.forEach((position4) => {
              if(position1.number !== position2.number && position1.number !== position3.number && position2.number !== position3.number && position1.number !== position4.number && position2.number !== position4.number && position3.number !== position4.number) {
                newData.push({
                  key: new Date().getTime().toString() + countIndex,
                  bet_id: item.bet_id,
                  name_front: item.name_front,
                  combinations: `${position1.number}-${position2.number}-${position3.number}-${position4.number}`,
                  wager_amount: 0,
                  payout_amount: 0,
                })
                countIndex++;
              }   
          })
          })
        })
      })
    } else if(item.bet_id === SUPERHIGHFIVE) {
      positionList1.forEach((position1) => {
        positionList2.forEach((position2) => {
          positionList3.forEach((position3) => {
            positionList4.forEach((position4) => {
              positionList5.forEach((position5) => {
                if(position1.number !== position2.number && position1.number !== position3.number && position2.number !== position3.number && position1.number !== position4.number && position2.number !== position4.number && position3.number !== position4.number && position1.number !== position5.number && position2.number !== position5.number && position3.number !== position5.number && position4.number !== position5.number) {
                  newData.push({
                    key: new Date().getTime().toString() + countIndex,
                    bet_id: item.bet_id,
                    name_front: item.name_front,
                    combinations: `${position1.number}-${position2.number}-${position3.number}-${position4.number}-${position5.number}`,
                    wager_amount: 0,
                    payout_amount: 0,
                  })
                  countIndex++;
                } 
              })
  
          })
          })
        })
      })
    } else {
      newData.push({
          key: new Date().getTime().toString() + countIndex,
          bet_id: item.bet_id,
          name_front: item.name_front,
          combinations: '',
          wager_amount: 0,
          payout_amount: 0,
      })
      countIndex++;
    }

     
  });

  
  return newData;


}