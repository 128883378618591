import connector from './connector.service'


export interface ProviderRacecourseItem {
    provider: string
    code: string
    name: string
    code_alt: string
}

export const getProviderRacecourseDay = async (provider: string): Promise<ProviderRacecourseItem[]> => {
    const racecourse:ProviderRacecourseItem[] = await connector.get(`/provider-racecourse/user/${provider}`)
    return racecourse
}