import { IRacecourseEvent, IRacecourseEventChangeStatusResponse } from "../../services/racecourse-event.service"
import { RACECOURSE_EVENT_SET_LIST_BY_DATE, RACECOURSE_EVENT_SET_STATUS } from "../action-types"

const initialState: {racecourseEventDate: IRacecourseEvent[]} = {
    racecourseEventDate: [],
}

const sortObjectsByStatusAndName = (arr: IRacecourseEvent[]): IRacecourseEvent[] => {
    return arr.sort((a, b) => {
        if (a.status < b.status) {
            return -1;
        }
        if (a.status > b.status) {
            return 1;
        }
        // si el status es el mismo, compara los nombres
        if (a.racecourse.name < b.racecourse.name) {
            return -1;
        }
        if (a.racecourse.name > b.racecourse.name) {
            return 1;
        }
        return 0;
    });
}
const RacecourseEventReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case RACECOURSE_EVENT_SET_LIST_BY_DATE: {
            const {raceEvents} = action.payload as {raceEvents: IRacecourseEvent[]}
            const raceEventsSorted = sortObjectsByStatusAndName([...raceEvents])
            return {...state, racecourseEventDate: raceEventsSorted}
        }
        case RACECOURSE_EVENT_SET_STATUS: {
            const {raceEventStatus} = action.payload  as {raceEventStatus: IRacecourseEventChangeStatusResponse}
            const racecourseEvent = state.racecourseEventDate.map((item: IRacecourseEvent) => {
                if(raceEventStatus._id === item._id) {
                    const clone = {...item}
                    clone.status = raceEventStatus.status
                    return clone;
                } else {
                    return item;
                }
            })
            const raceEventsSorted = sortObjectsByStatusAndName([...racecourseEvent])
            return {...state, racecourseEventDate: raceEventsSorted}   
        }

        default:
            return state
        break;
    }
}


export default RacecourseEventReducer