import { Card, List, Table } from "antd"
import { useEffect, useState } from "react"
import { RaceInfoStatus } from "../services/racecourse-race.service"
import { ResultItem, Betresult, BET_TYPES_CORE } from "../services/result.service"

export interface BasicResultInterface {
    runner_number: number
    bet_results: {
        bet_id: number
        wager_amount: number
        payout_amount: number
    }[]

}

interface BasicResultDataInterface {
    runner_number: number
    win: string
    place: string
    show: string
}



export const ResultItemListDetail = ({ result }: { result: ResultItem }) => {

    const columnsBasicTypes = [
        {
          title: 'N° Ejemplar',
          dataIndex: 'runner_number',
          key: 'runner_number',
        },
        {
            title: 'Win',
            dataIndex: 'win',
            key: 'win',
        },
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: 'Show',
            dataIndex: 'show',
            key: 'show',
        },
    ]

    const columnsExotics = [
        {
            title: 'Bet',
            dataIndex: 'bet_name',
            key: 'bet_name',
        },
        {
          title: 'Combinacion',
          dataIndex: 'runners',
          key: 'runners',
        },
        {
            title: 'Costo',
            dataIndex: 'wager_amount',
            key: 'wager_amount',
        },
        {
            title: 'Dividendo',
            dataIndex: 'payout_amount',
            key: 'payout_amount',
        },

    ]

    const calculateTimeLeft = (timeA: number, timeB: number) => {
        let inverse = false
        let  difference = (timeA-  timeB)
        if(timeA < timeB)  {
            inverse = true
            difference =  timeB - timeA
        }


        
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((difference / 1000 / 60) % 60)
        const seconds = Math.floor((difference / 1000) % 60)
        
        const displayHour = hours < 10 ? '0'+ hours : hours + ''
        const displayMinutes = minutes < 10 ? '0'+ minutes : minutes + ''
        const displaySeconds = seconds < 10 ? '0'+ seconds : seconds + ''
        if(inverse) {
            return <span style={{fontWeight:'bold', color: 'red'}}> -{`${displayHour}:${displayMinutes}:${displaySeconds}`} </span>
        } else {
            return <span> {`${displayHour}:${displayMinutes}:${displaySeconds}`} </span>
        }
    }

    const getBasicBets =  () => {
        return result.bet_results.filter ((item) => item.bet_id >= 10 && item.bet_id <= 30)
        .reduce((acc:BasicResultInterface[], item: Betresult)=> {
            
            let itemRegistered: BasicResultInterface = acc.find((subItem: BasicResultInterface) => subItem.runner_number === item.combinations[0].runner_number[0]) as BasicResultInterface
            if(itemRegistered !== undefined) {
                const betResult = {
                    bet_id: item.bet_id,
                    wager_amount: item.wager_amount,
                    payout_amount: item.payout_amount,
                }
                return [...acc.filter((subItem:BasicResultInterface) => subItem.runner_number !== item.combinations[0].runner_number[0]),
                    {...itemRegistered, bet_results: [...itemRegistered.bet_results, betResult]}
                ]

            } else {
                console.log('item', item)
                const newItem:BasicResultInterface = {
                    runner_number: item.combinations[0].runner_number[0], 
                    bet_results: [{
                        bet_id: item.bet_id,
                        wager_amount: item.wager_amount,
                        payout_amount: item.payout_amount,
                    }]
                }
                return [...acc,  newItem]
            }
        }, [])
        .sort((a,b) => b.bet_results.length - a.bet_results.length)
        .map((item:BasicResultInterface) => {
            const items = item.bet_results
            //.sort((a,b) => a.bet_id - b.bet_id)
            
            const itemPayout = [10,20,30].reduce((acc: any, subItem)=> {
                const finded= items.find(i => i.bet_id === subItem)
                let tag = 'win'
                if(subItem === 20) {
                    tag = 'place'
                } else if(subItem === 30) {
                    tag = 'show'
                }
                if(finded) {
                    return {...acc, [tag]: finded.payout_amount.toString()}
                } else {
                    return {...acc, [tag]: '-'}
                }
            }, {})
            return {
                runner_number: item.runner_number,
                ...itemPayout
            }

        })

    }

    return <>

        <List.Item>
            <Card style={{ width: '100%' }} title={`${result.racecourse_event_race.racecourse_event.racecourse.name.toUpperCase()} - CARRERA: ${result.racecourse_event_race.number}`}>
                <p>Hora de Estimada de Cierre {new Date(result.racecourse_event_race.post_time).toLocaleTimeString()}</p>
                <p>Hora de cierre {new Date(result.racecourse_event_race.date_close_finished).toLocaleTimeString()}</p>
                <p>Diferencia entre cierre programado y cierre efectivo: {calculateTimeLeft(result.racecourse_event_race.post_time, +new Date(result.racecourse_event_race.date_close_finished))}</p>
                <p>Hora de Resultados {new Date(result.created_at).toLocaleTimeString()}</p>
                <p>Tiempo Transcurrido entre finalizacion y resultados: {calculateTimeLeft(+new Date(result.created_at), +new Date(result.racecourse_event_race.date_close_finished) )}</p>            
                <Table 
                    pagination={false}
                    columns={columnsBasicTypes}
                    rowKey='runner_number'
                    dataSource={getBasicBets()}
                > </Table>


                <Table 
                    pagination={false}
                    columns={columnsExotics}
                    rowKey='runners'
                    dataSource={
                        result.bet_results.filter(item => item.bet_id > 30)
                        .reduce ((acc:any[], item) => {
                            const betName = BET_TYPES_CORE.find(i => i.bet_id === item.bet_id)?.name_front || ''
                            
                            const itemsByRace = item.combinations.reduce((acc2, item2)=>{
                                return acc2 + ' ' + item2.race_number + '/' + item2.runner_number.join('-')
                            }, '')
                            return [...acc, {runners: itemsByRace, wager_amount: item.wager_amount, payout_amount: item.payout_amount, bet_name: betName}]
                        },[])
                    }
                > </Table>
            </Card>

        </List.Item>
    </>
}