import { ResultItem } from "../../services/result.service";
import { RACECOURSE_EVENT_RACE_RESULT_SET_LIST, RACECOURSE_EVENT_RACE_RESULT_ADD_ITEM, RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS} from "../action-types";



export const resultSetList = (data:any[]) => ({
    type: RACECOURSE_EVENT_RACE_RESULT_SET_LIST,
    payload: { 
        data
    }
})


export const resultAddItem = (result: ResultItem) => ({
    type: RACECOURSE_EVENT_RACE_RESULT_ADD_ITEM,
    payload: {result}
})

export const resultAddMoreList = (data:any[]) => ({
    type: RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS,
    payload: { 
        data
    }
})


