import React, { useState } from "react";
import { Select, Button, Row, Col } from "antd";

const { Option } = Select;

interface Runner {
  number: number;
  name: string;
}

interface PositionSetterProps {
  runners: Runner[];
  dispatch: (result: { number: number; position: number }[]) => void;
}

const PositionSetter: React.FC<PositionSetterProps> = ({
  runners,
  dispatch,
}) => {
  const [selectedRunners, setSelectedRunners] = useState<{
    [key: string]: number[];
  }>({});
  const [allSelectedNumbers, setAllSelectedNumbers] = useState<number[]>([]);

  const handleSelectChange = (value: number[], position: number) => {
    // Actualizar selectedRunners
    setSelectedRunners((prev) => ({ ...prev, [position]: value }));

    // Actualizar allSelectedNumbers excluyendo la posición actual y luego agregando los valores seleccionados
    const numbersFromOtherPositions = Object.entries(selectedRunners)
      .filter(([pos]) => pos !== position.toString())
      .flatMap(([_, nums]) => nums);

    setAllSelectedNumbers([...numbersFromOtherPositions, ...value]);
  };

  const handleSubmit = () => {
    const result: { number: number; position: number }[] = [];
    Object.entries(selectedRunners).forEach(([position, numbers]) => {
      numbers.forEach((num) => {
        result.push({
          number: num,
          position: parseInt(position),
        });
      });
    });
    dispatch(result);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={[16, 16]}>
        {runners.map((_, index) => (
          <Col key={index} span={24}>
            <Row gutter={[8, 8]} align="middle">
              <Col>
                <span>Posición {index + 1}:</span>
              </Col>
              <Col>
                <Select
                  mode="multiple"
                  style={{ width: 200 }}
                  placeholder="Selecciona corredor(es)"
                  value={selectedRunners[index + 1] || []}
                  onChange={(value: number[]) =>
                    handleSelectChange(value, index + 1)
                  }
                >
                  {runners.map((runner) => (
                    <Option
                      key={runner.number}
                      value={runner.number}
                      disabled={
                        allSelectedNumbers.includes(runner.number) &&
                        !(selectedRunners[index + 1] || []).includes(
                          runner.number
                        )
                      }
                    >
                      {runner.number} - {runner.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Button type="primary" onClick={handleSubmit}>Siguiente</Button>
        </Col>
      </Row>
    </div>
  );
};

export default PositionSetter;
