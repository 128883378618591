import { Table, Button, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IRacecourseEvent, updateRacecourseEventStatusById } from '../services/racecourse-event.service';
import { useDispatch } from 'react-redux';
import { messageSystemPush } from '../redux/actions/message-system';
import { RACECOURSE_STATUS } from '../services/racecourse.service';
import { updateStatusRacecourseEvent } from '../redux/actions/racecourse-event';
import { RACECOURSE_EVENT_RACE_STATUS, RaceEventRaceSimple, getRacesStatus, updateStatusById } from '../services/racecourse-race.service';
import { CloseCircleOutlined , ClockCircleOutlined,PlayCircleOutlined , CheckCircleOutlined, LockOutlined,WarningOutlined} from '@ant-design/icons';
import { raceStatusSetList, updateStatusRacecourseEventRace } from '../redux/actions/race';

interface RacecourseEventTableProps {
  onSelectEvent: (id: string) => void;
  onGetResult:  (racecourseRaceId: string) => Promise<void>;
  racecourseEventRaceList: RaceEventRaceSimple[];
}

const RacecourseEventRaceTable: React.FC<RacecourseEventTableProps> = ({ onSelectEvent, racecourseEventRaceList, onGetResult }) => {
  const dispatch = useDispatch();

  const statusOptions = {
    [RACECOURSE_EVENT_RACE_STATUS.CLOSED]: { key: 'closed', displayName: 'CERRADA' },
    [RACECOURSE_EVENT_RACE_STATUS.STAND_BY]: { key: 'stand_by', displayName: 'EN ESPERA' },
    [RACECOURSE_EVENT_RACE_STATUS.OPEN]: { key: 'open', displayName: 'ACTIVA' },
    [RACECOURSE_EVENT_RACE_STATUS.LOCKED]: { key: 'locked', displayName: 'BLOQUEADA' },
    [RACECOURSE_EVENT_RACE_STATUS.FINISHED]: { key: 'finished', displayName: 'FINALIZADA' },
    [RACECOURSE_EVENT_RACE_STATUS.INVALIDATED]: { key: 'invalidated', displayName: 'INVALIDADA' },
  };
  

  const handleChangeStatus = async (id:string, statusTarget: string) => {
    try {
      const raceEventRaceSimple = await racecourseEventRaceList.find((raceEventRaceSimple) => raceEventRaceSimple._id === id);
      if(!raceEventRaceSimple) return;
      const racecourseEvents = await updateStatusById(raceEventRaceSimple._id, statusTarget);
      dispatch(updateStatusRacecourseEventRace(racecourseEvents))
      const races = await getRacesStatus()
      dispatch(raceStatusSetList(races))
    } catch (error) {
      dispatch(messageSystemPush(error))
    } finally {
    }
  }


  const getRowClassName = (record: RaceEventRaceSimple) => {
    switch(record.status){
      case RACECOURSE_EVENT_RACE_STATUS.CLOSED:
        return 'row-status-closed';
      case RACECOURSE_EVENT_RACE_STATUS.STAND_BY:
        return 'row-status-standby';
      case RACECOURSE_EVENT_RACE_STATUS.OPEN:
        return 'row-status-open';
      case RACECOURSE_EVENT_RACE_STATUS.LOCKED:
        return 'row-status-locked';
      case RACECOURSE_EVENT_RACE_STATUS.FINISHED:
        return 'row-status-finished';
      case RACECOURSE_EVENT_RACE_STATUS.INVALIDATED:
        return 'row-status-invalidated';
      default:
        return 'row-status-invalidated';
    }
}

const getButtonClass = (status: string) => {
    switch(status){
      case RACECOURSE_EVENT_RACE_STATUS.CLOSED:
        return 'button-closed';
      case RACECOURSE_EVENT_RACE_STATUS.STAND_BY:
        return 'button-standby';
      case RACECOURSE_EVENT_RACE_STATUS.OPEN:
        return 'button-open';
      case RACECOURSE_EVENT_RACE_STATUS.LOCKED:
        return 'button-locked';
      case RACECOURSE_EVENT_RACE_STATUS.FINISHED:
        return 'button-finished';
      case RACECOURSE_EVENT_RACE_STATUS.INVALIDATED:
        return 'button-invalidated';
      default:
        return '';
    }
}




  const columns: ColumnsType<RaceEventRaceSimple> = [
    {
      title: 'Carrera',
      dataIndex: ['number'],
      key: 'number',
      render: (number: string) => <span>CARR: {number}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // customize the status cell as needed
      render: (status: string) => {
        const statusToShow = statusOptions[status]?.displayName || 'SIN ESTADO';
        return (<span>{statusToShow}</span>);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_text: string, record: RaceEventRaceSimple) => {
        return (
          <div>
            <Space style={{marginTop: '.2em'}}>
              <Tooltip title="Cambiar a Cerrada">
                <Button 
                 className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.CLOSED)}
                onClick={()=> handleChangeStatus(record._id, RACECOURSE_EVENT_RACE_STATUS.CLOSED)} size="small" icon={<CloseCircleOutlined/>}/>
              </Tooltip>
              <Tooltip title="Cambiar a En Espera">
                <Button 
                 className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.STAND_BY)}
                onClick={()=> handleChangeStatus(record._id,RACECOURSE_EVENT_RACE_STATUS.STAND_BY)} size="small" icon={<ClockCircleOutlined/>} />
              </Tooltip>
              <Tooltip title="Cambiar a Activa">
                <Button 
                 className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.OPEN)}
                onClick={()=> handleChangeStatus(record._id,RACECOURSE_EVENT_RACE_STATUS.OPEN)}  size="small" icon={<PlayCircleOutlined />} />
              </Tooltip>
              <Tooltip title="Cambiar a Bloqueada">
                <Button 
                 className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.LOCKED)}
                onClick={()=> handleChangeStatus(record._id,RACECOURSE_EVENT_RACE_STATUS.LOCKED)} size="small" icon={<LockOutlined />} />
              </Tooltip>
              <Tooltip title="Cambiar a Finalizada">
                <Button 
                 className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.FINISHED)}
                onClick={()=> handleChangeStatus(record._id,RACECOURSE_EVENT_RACE_STATUS.FINISHED)}  size="small" icon={<CheckCircleOutlined />} > Finalizar </Button>
              </Tooltip>
              <Tooltip title="Cambiar a Invalidada">
                <Button 
                 className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.INVALIDATED)}
                onClick={()=> handleChangeStatus(record._id,RACECOURSE_EVENT_RACE_STATUS.INVALIDATED)}  size="small" icon={<WarningOutlined />} />
              </Tooltip>


            </Space>

            <Space style={{marginTop: '.2em', marginLeft: '.2em'}}>

                  <Button 
                  className={getButtonClass(RACECOURSE_EVENT_RACE_STATUS.FINISHED)}
                  onClick={()=> onSelectEvent(record._id)}  size="small" icon={<CheckCircleOutlined />} > EJEMPLARES </Button>

                  <Button
                  disabled={!record.have_results}
                  onClick={()=> onGetResult(record._id)}  size="small" icon={<CheckCircleOutlined />} > RESULTADOS </Button>

            </Space>
          </div>
        );
      }
    },
  ];

  return (
    <Table
    pagination = {false} 
    columns={columns} dataSource={racecourseEventRaceList} rowKey="_id" rowClassName={getRowClassName} />
  );
};

export default RacecourseEventRaceTable;
