import { Button, Row, Table, Col, Input } from "antd"
import { Content } from "antd/lib/layout/layout"
import React, { SyntheticEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ButtonGo } from "../Components/ButtonGo"
import { messageSystemPush } from "../redux/actions/message-system"
import { racecourseSetList } from "../redux/actions/racecourse"
import { RootState } from "../redux/store"
import { getRacecourseList, getRacecourseListFiltered } from "../services/racecourse.service"


export const Racecourse = ()=> {
    const columns = [
        {title: 'Nombre', dataIndex: 'name', sorter: true, width: '30%'},
        {title: 'Status', dataIndex: 'status', sorter: true, width: '15%'},
        {title: 'Clase', dataIndex: 'racecourse_class', sorter: true, width: '15%'},
        {title: 'Tipo', dataIndex: 'type', sorter: true, width: '15%'},	
        {title: 'Proveedores', dataIndex: 'providers', width: '30%', render: (item:any[]) => item.map(s => s.provider).join(', ')},
        {title: 'Fecha registro', dataIndex: 'created_at', width: '20%', render: (item:string) => new Date(item).toDateString()},
    ]

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    //const [loadingFiltered, setLoadingFiltered] = useState(false)
    const [selectedKeys, selectKeys] = useState<React.Key[]>([])
    const [valueFilter, setValueFilter] = useState('')

    const {data, pageSize, current, total} = useSelector<RootState>(state => (state.racecourse)  ) as {data: any[], pageSize: number, current: number, total: number}

    const goToProviderManage = () => {
        return `/racecourse/provider-manage/${selectedKeys[0]}`
    }

    const goToEdit = () => {
        return `/racecourse/edit/${selectedKeys[0]}`
    }



    const loadData = async () => {
        try {
            setLoading(true)
            const data = await getRacecourseList()
            dispatch(racecourseSetList(data))

        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }

    }


    const handleFilteredChanged = (value: any) => {
        console.log('value', value.target.value)

        filteredData(value.target.value)

    }

    const filteredData = async (value: string) => {
        try {
            setValueFilter(value);
            const sleep = (ms: number)=> {
                return new Promise((resolve)=> {
                    window.setTimeout(()=> {
                        resolve(true)
                    }, ms)
                }) 
            }
            await sleep(200)
            if( value.length > 2) {
                //setLoadingFiltered(true)

                const data= await getRacecourseListFiltered(value)
                dispatch(racecourseSetList(data))
            }
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            //setLoadingFiltered(false)
        }
    }

    useEffect(() => {
        if(valueFilter.length === 0) {
            loadData()
        }

    }, [valueFilter])

    const onChangeSelectionRow = (rowsKeys:React.Key[], rows:any)=> {
        selectKeys(rowsKeys)
    }

    return <>
        <Content style={{padding: '.3em'}}>
        <Row>
            <Col xs={24} sm={24} md={12} lg={6} xl={{span: 8, offset: 1}}>

            <Input value={valueFilter} onChange={handleFilteredChanged} size="large" placeholder="Ingrese un valor de busqueda" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={{span: 4, offset: 1}}>

            <Button block type="primary">
                <Link to="/racecourse/new">
                    Nuevo Hipodromo
                </Link>
            </Button>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={{span: 4, offset: 1}}>
            <Button block type="primary" disabled={(selectedKeys.length <= 0)}>
                <Link to={goToProviderManage}>
                    Gestionar Proveedores
                </Link>
            </Button>


            </Col>

            <Col xs={24} sm={24} md={12} lg={4} xl={{span: 4, offset: 1}}>
            <ButtonGo title="Editar Hipodromo" path={goToEdit} disabled={(selectedKeys.length <= 0)}></ButtonGo>
                </Col>
        </Row>

        <Table
        columns={columns}
        rowKey={record => record._id}
        dataSource={data}
        pagination={false}
        rowSelection={{onChange: onChangeSelectionRow, selectedRowKeys: selectedKeys, type: 'radio', columnWidth: '32px'}}
        loading={loading}
      />
        </Content>

    </>
}