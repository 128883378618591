import connector from './connector.service'
import { PaginationDataResponse } from './interfaces/pagination.response.interface'

export const getBlackItemList = async (lastId?: string):Promise<BlackItemInterface[]> => {

    let query = '';
    if(lastId) {
        query = `?last_id=${lastId}`
    }

    const racecourseList:BlackItemInterface[] = await connector.get(`/black-item/list-paginated-complete-today${query}`)
    return racecourseList;

}


export const getBlackItem = async (id: string):Promise<BlackItemInterface> => {
    const racecourseItem:BlackItemInterface = await connector.get(`/black-item/today/${id}`)
    return racecourseItem

}


export enum BLACK_ITEM_TYPE_ITEM {
    RACE = 'race',
    RACECOURSE = 'racecourse'
}

export enum BLACK_ITEM_MODULE {
    RESULT_CREATE = 'RESULT_CREATE',
    EVENT_CREATE = 'EVENT_CREATE'
}



export interface RacecourseList {
    docs: BlackItemInterface [],
    totalDocs: number,
    limit: number,
    totalPages: number,
    page: number,
    pagingCounter: number,
    hasPrevPage: boolean,
    hasNextPage: boolean,
    prevPage: number | null,
    nextPage: number | null,
}

export interface BlackItemInterface {
    _id: string,
    date: string, 
    item_id: string,
    type_item: BLACK_ITEM_TYPE_ITEM
    module: BLACK_ITEM_MODULE,
    created_at: string,
    updated_at: string,

}