import { Menu } from "antd"
import Sider from "antd/lib/layout/Sider"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Link,
    useLocation 
} from 'react-router-dom';
import { RootState } from "../redux/store";

export const Sidebar = () => {

    const userData = useSelector<RootState>(state => (state.auth  )) as {name: string, username: string} 
    const location = useLocation()
    const [key, setKey] = useState('1')

    useEffect(()=> {
        checkRoute()
    }, [])

    const checkRoute = () => {
        switch (location.pathname) {
            case '/':
                setKey('1')
                break;
            case '/racecourse-event/create':
                setKey('2')
                break;
            case '/racecourse' || '/racecourse/new' || '/racecourse/provider-manager':
                setKey('3')
                break;
            case '/provider/racecourse/provider-a':
                setKey('4')
                break;
            case '/provider/racecourse/provider-b':
                setKey('5')
                break;
        
    
        }
    }


    return <>
        <Sider style={{height: '100vh'}}  collapsible={true} breakpoint="xs">
            

            {userData && userData?.name &&  (<Menu theme="dark" mode="vertical" defaultSelectedKeys={[key]} >
                <Menu.Item key="1"><Link to="/" >Home</Link></Menu.Item>
                <Menu.Item key="2"><Link to="/racecourse-event/create" >Crear Evento</Link></Menu.Item>
                <Menu.Item key="3"><Link to="/racecourse" >Gestionar Hipodromos</Link></Menu.Item>
                <Menu.Item key="4"><Link to="/provider/racecourse/provider-a" >Hipodromos por proveedor A</Link> </Menu.Item>
                <Menu.Item key="5"><Link to="/provider/racecourse/provider-b" >Hipodromos por proveedor B</Link> </Menu.Item>
            </Menu>) }

           

        </Sider>
    </>
}