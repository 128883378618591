
import connector from './connector.service'
export const checkUserAuth = async ()=> {
    const userData = await connector.get('/auth')
    const {_id: id, name, username, rol} = userData
    return {id, name, username, rol}
}


export const login = async (username: string, password: string) => {
        const userData = await connector.post('auth',
        {
            username, password
        })

        const {token} = userData
        window.localStorage.setItem('token-auth', token)

        const {id, name, rol} = await checkUserAuth()
        return {id, username, name, rol}

}