import { Table, Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IRacecourseEvent, updateRacecourseEventStatusById } from '../services/racecourse-event.service';
import { useDispatch } from 'react-redux';
import { messageSystemPush } from '../redux/actions/message-system';
import { RACECOURSE_STATUS } from '../services/racecourse.service';
import { updateStatusRacecourseEvent } from '../redux/actions/racecourse-event';
import { getRacesStatus } from '../services/racecourse-race.service';
import { raceStatusSetList } from '../redux/actions/race';

interface RacecourseEventTableProps {
  onSelectEvent: (id: string) => void;
  racecourseEventDate: IRacecourseEvent[];
}

const RacecourseEventTable: React.FC<RacecourseEventTableProps> = ({ onSelectEvent, racecourseEventDate }) => {
  const dispatch = useDispatch();
  

  const handleChangeStatus = async (id: string) => {

    const racecourseEvent = racecourseEventDate.find((racecourseEvent) => racecourseEvent._id === id);

    if(!racecourseEvent) return;

    const statusTarget = racecourseEvent.status?.toUpperCase() === 'ACTIVE' ? RACECOURSE_STATUS.INACTIVE : RACECOURSE_STATUS.ACTIVE
    
    try {
      const racecourseEvents = await updateRacecourseEventStatusById(id, statusTarget);
      dispatch(updateStatusRacecourseEvent(racecourseEvents))

      const races = await getRacesStatus();
      dispatch(raceStatusSetList(races));

    } catch (error) {
      dispatch(messageSystemPush(error))
    } finally {
    }
  }

  const getRowClassName = (record: IRacecourseEvent) => {
    // Define tu condición aquí, he usado status como ejemplo
    return record.status === 'active' ? 'row-active' : 'row-inactive';
  };

  const columns: ColumnsType<IRacecourseEvent> = [
    {
      title: 'Racecourse',
      dataIndex: ['racecourse', 'name'],
      key: 'racecourse',
      render: (name: string) => <span>{name.toUpperCase()}</span>,
    },
    /*{
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // customize the status cell as needed
      render: (status: string) => {
        const statusToShow = status === RACECOURSE_STATUS.ACTIVE ? 'ACTIVO' : 'INACTIVO';
        return (<span>{statusToShow}</span>);
      },
    },*/
    {
      title: 'Actions',
      key: 'actions',
      render: (_text: string, record: IRacecourseEvent) => {
        const buttonTitleToShow = record.status?.toUpperCase() === 'ACTIVE' ? 'INACTIVAR' : 'ACTIVAR';
        return (
          <Space>
            <Button onClick={() => handleChangeStatus(record._id)}>{buttonTitleToShow}</Button>
            <Button onClick={() => onSelectEvent(record._id)}>DETALLE</Button>
          </Space>
        );
      }
    },
  ];

  return (
    <Table 
    pagination = {false}
    columns={columns} dataSource={racecourseEventDate} rowKey="_id" rowClassName={getRowClassName} />
  );
};

export default RacecourseEventTable;
