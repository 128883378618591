import { RACECOURSE_EVENT_RACE_STATUS, RaceEventRaceSimple, RaceInfoStatus } from "../../services/racecourse-race.service"
import { RACECOURSE_EVENT_RACE_SET_LIST, RACECOURSE_EVENT_RACE_UPDATE_ITEM, RACECOURSE_EVENT_RACE_DATE_GET_LIST, RACECOURSE_EVENT_RACE_UPDATE_STATUS, RACECOURSE_EVENT_RACE_RUNNER_UPDATE_STATUS} from "../action-types"

const initialState: {races: any[], racesByDate: RaceInfoStatus[]} = {
    races: [],
    racesByDate: []
}


const RaceReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case RACECOURSE_EVENT_RACE_SET_LIST:
            const {races} = action.payload as {races: RaceInfoStatus[]};
            return {...state, races: races};

        case RACECOURSE_EVENT_RACE_UPDATE_ITEM:{
            const {race} = action.payload  as {race: RaceInfoStatus};
            let finded= false
            const raceStandByOrOpen = state.races.map((item: RaceInfoStatus) => {
                if(race._id === item._id) {
                    finded = true
                    return race
                } else {
                    return item
                }
            })
            .filter(item => item.status === RACECOURSE_EVENT_RACE_STATUS.STAND_BY || item.status === RACECOURSE_EVENT_RACE_STATUS.OPEN)
            
            if(finded === false) {
                raceStandByOrOpen.push(race)
            }
            return {...state, races: raceStandByOrOpen}
        }
        case RACECOURSE_EVENT_RACE_DATE_GET_LIST: {
            const {racesByDate} = action.payload as {racesByDate: RaceEventRaceSimple[]};
            return {...state, racesByDate: racesByDate};
        }
        case RACECOURSE_EVENT_RACE_UPDATE_STATUS: {
            const {race} = action.payload as {race: RaceEventRaceSimple};
            const racesByDate = state.racesByDate.map((item: RaceEventRaceSimple) => {
                if(item._id === race._id) {
                    return race;
                }
                return item;
            });
            return {...state, racesByDate: racesByDate};
        }
        case RACECOURSE_EVENT_RACE_RUNNER_UPDATE_STATUS: {
            const {race} = action.payload as {race: RaceEventRaceSimple};
            const racesByDate = state.racesByDate.map((item: RaceEventRaceSimple) => {
                if(item._id === race._id) {
                    return race;
                }
                return item;
            });
            return {...state, racesByDate: racesByDate};
        }
        default:
            return state
        break;
    }
}


export default RaceReducer