import connector from './connector.service'
import { RacecourseEventRacePendingResult } from './interfaces/racecourse-event-race-pending-result'
import { IResultInput } from './interfaces/racecourse-event-result-input'
import { IRacecourseRaceProviderResult } from './interfaces/racecourse-race-result-provider-pending';
export const RACECOURSE_EVENT_STATUS = {
    CLOSED: 'closed',
    ACTIVE: 'active',
    LOCKED: 'locked',
    FINISHED: 'finished',
    ERASED: 'erased'
}


export const RACECOURSE_EVENT_RACE_STATUS = {
    CLOSED: 'closed',
    STAND_BY: 'stand_by',
    OPEN: 'open',
    LOCKED: 'locked',
    FINISHED: 'finished',
    INVALIDATED: 'invalidated'
}


export const RUNNER_STATUS = {
    SCRATCHED: true,
    ACTIVE: false
}



export interface RaceInfoStatus {
    _id: string,
    status: string,
    number: number,
    post_time: number,
    minutes_to_start: number,
    bet_types: {
        _id: string,
        name_front: string,
        bet_id: number,
        name: string
    }[],
    runners: IRaceEventRaceRunner [],
    racecourse_event: {
        date: string,
        status: string,
        racecourse: {
            _id: string,
            name: string,
        }
    },
    last_update: string
    have_results: boolean
}




export type RaceEventRaceSimple = Omit<RaceInfoStatus, 'racecourse_event'>

export type IRaceEventRaceRunner = {
    scratched: boolean,
    _id: string,
    number: number,
    odds: string,
    name: string 
}

export const getRacesStatus = async (): Promise<RaceInfoStatus[]> => {
    const races:RaceInfoStatus[] = await connector.get(`/racecourse-event-race/get-active-standby-today`)
    return races.map(item => ({...item, last_update: new Date().toLocaleTimeString()}))
}

export const getRaceById = async (id:string):Promise<RaceInfoStatus> => {
    //find-complete-by-id/:id
    const race:RaceInfoStatus = await connector.get(`/racecourse-event-race/find-complete-by-id/${id}`)
    return {...race, last_update: new Date().toLocaleTimeString()}
}

export const updateStatusById = async (id:string, status: string):Promise<RaceInfoStatus> => {
    //update-status/:id/:status
    const race:RaceInfoStatus = await connector.put(`/racecourse-event-race/update-status/${id}/${status}`, {})
    return {...race, last_update: new Date().toLocaleTimeString()}
}

export const listByRacecourseEventId = async (racecourseEventId:string):Promise<RaceEventRaceSimple[]> => {
    const races:RaceEventRaceSimple[] = await connector.get(`/racecourse-event-race/list/by-racecourse-event/${racecourseEventId}`)
    return races.map((item) => ({...item, last_update: new Date().toLocaleTimeString()}));
}


//runners/update-status/:id/:runnerId/:status
export const updateRunnerByStatusId = async (id: string, runnerId: string, status: boolean) => {
    const race:RaceInfoStatus = await connector.put(`/racecourse-event-race/runners/update-status/${id}/${runnerId}/${status}`, {})
    return {...race, last_update: new Date().toLocaleTimeString()}
}


export const getListPendingForResults = async (date: string) => {
    const race:RacecourseEventRacePendingResult[] = await connector.get(`/racecourse-event-race/get-list-pending-results/${date}`)
    return race
}


export const createResults = async (payload: IResultInput) => {
    const response = await connector.post('/racecourse-event-race-result', payload);
    return response;
}

export const getPendingResultsFromProvider = async () => {
    const raceProviderResult:IRacecourseRaceProviderResult[] = await connector.get(`/racecourse-event-race-result/find-pending-results-from-provider`)
    return raceProviderResult
}

export const createResultsFromProvider = async (id: string) => {
    //create-from-provider-user/:id
    const raceProviderResult:IRacecourseRaceProviderResult[] = await connector.post(`/racecourse-event-race-result/create-from-provider-user/${id}`, {})
    return raceProviderResult

}

export const deleteResults = async (id: string) => {
    //'delete-from-provider-user/:id
    const response = await connector.post(`/racecourse-event-race-result/delete-from-provider-user/${id}`, {});
    return true;
}