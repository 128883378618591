import { Col, Row, List, Button } from "antd"
import { Content } from "antd/lib/layout/layout"
import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { BlackItemInterface } from "../../services/black-item.service"
import {BlackListItem} from "./BlackListItem"
import {getBlackItemList} from "../../services/black-item.service"
import { blackItemTodaySetList, blackItemTodayAddMoreItems } from "../../redux/actions/black-item"
import { messageSystemPush } from "../../redux/actions/message-system"

export const DashboardBlackItem = () => {
    const dispatch = useDispatch()
    const { data, pageSize, current, total } = useSelector<RootState>(state => (state.blackItem)) as { data: any[], pageSize: number, current: number, total: number }
    useEffect(()=> {
        loadData()
    }, [])


    const loadData = async () => {
        try {
         
            const data = await getBlackItemList()
            dispatch(blackItemTodaySetList(data))
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
        }
    }

    const loadingMoreResultItems = async () => {
        try {
            const lastId = data[data.length - 1]?._id
            const dataResponse = await getBlackItemList(lastId)
            dispatch(blackItemTodayAddMoreItems(dataResponse))


        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
        }
    }

    return <>
        <Content style={{ padding: '.3em' }}>

            <Row>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <h3>Errores Registrados</h3>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item: BlackItemInterface ) => (
                            <BlackListItem blackItem={item}></BlackListItem>
                        )}
                    />

                <Button onClick={loadingMoreResultItems} style={{textAlign: 'center'}}>Obtener mas</Button>
                </Col>
            </Row>
        </Content>
    </>
}