import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../redux/store"
import {} from 'redux'
import { messageSystemClear } from "../redux/actions/message-system"
import {Button, PageHeader} from 'antd'
import {notification} from 'antd'

import { LogoutOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { authLogout } from "../redux/actions/auth"
import { useHistory } from 'react-router-dom'
import { getStatus, sendPing, updateStatus } from "../services/status-control.service"

type PropsHeader = {
    name: string, username: string
}


export const Header = ({name, username}: PropsHeader) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const {message, statusCode} = useSelector<RootState>(state => (state.messageSystem  )) as {message: string, statusCode: number}

    const [titleStatus, setTitleStatus] = useState<string>('Esperando conexion...')
    const [statusSystem, setStatusSystem] = useState<{
        status: boolean;
        error?: string | undefined;
        service_available_configured?: boolean | undefined;
        service_available?: boolean | undefined;
        copilot_mode_enable?: boolean | undefined;
        last_ping?: string | undefined;
    }>({status: false, error: 'Pendiente'})

    let loadingPing = false
    let loadingGetStatus = false

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const interval = setInterval(() => {
            pingSystem()
            loadGetStatus()
        }, 3000)
        return () => clearInterval(interval)
    
    }, [])

    const pingSystem = async () => {
        if(loadingPing) return
        try {
            loadingPing = true
            const status = await sendPing()
            // todo make actions here to know if the system is online or offline
        } catch (error) {
            
        }finally {
            loadingPing = false
        }

    }

    useEffect(() => {
        if(statusSystem.status) {
            if(statusSystem.service_available) {
                setTitleStatus('Servicio Activo')
            } else {
                setTitleStatus('Servicio Inactivo')
            }
        } else {
            setTitleStatus('Problemas para validar status...')
        }
    }, [statusSystem])

    const loadGetStatus = async () => {
        if(loadingGetStatus) return
        try {
            loadingGetStatus = true
            const status = await getStatus()
            setStatusSystem({...status})
        } catch (error) {
            console.log('error', error)
        } finally {
            loadingGetStatus = false
            setLoading(false)
        }
    }

    const changeStatus = async (status: boolean) => {
        if(loading) return
        setLoading(true)
        try {
            const response = await updateStatus(status)
        } catch (error) {
            console.log('error', error)
        } finally {
            
        }
    }

    const handleLogout  = () => {
        console.log('Yeah man')
        dispatch(authLogout())
        history.push('/login')
        
    }
    
    const buttonsAuth = <Button onClick={handleLogout} key="1" type="primary"  icon={<LogoutOutlined />}> {username} </Button>
    const buttonInactiveStatus = <Button disabled={loading} onClick={()=> changeStatus(false)} key="2" type="primary" danger icon={<LockOutlined />}> {loading ? 'Cambiando status...': 'Bloquear Sistema'}  </Button>
    const buttonActiveStatus = <Button disabled={loading}  onClick={()=> changeStatus(true)} key="3" type="primary" icon={<UnlockOutlined />}> {loading ? 'Cambiando status...': 'Desbloquear Sistema'}  </Button>
    const buttons = statusSystem.status === true ? statusSystem.service_available_configured === true ? [buttonInactiveStatus] : [buttonActiveStatus]: []
    

    useEffect(()=> {
        console.log(statusCode)
        if(statusCode !== 0) {
            notification.open({
                message: 'Error',
                description: message,
                duration: 5
            });
            
            window.setTimeout(()=> {
                dispatch(messageSystemClear())
            }, 5000)
        }
    },[statusCode])

    return <>
        <PageHeader
            
            ghost={false}
            title={<span style={{ color: statusSystem.service_available ? 'green' : 'red' }}>{titleStatus}</span>}
            subTitle={statusSystem?.last_ping ? 'Ultimo ping: '+ new Date(statusSystem.last_ping).toLocaleTimeString() : 'Sin conexion'}
            extra={name!='' ? [...buttons, buttonsAuth ] : []}
        />
        
    </>






}