export const DEFAULT_TIMEZONE = 'America/Caracas';

export const WIN = 10;
export const PLACE = 20;
export const SHOW = 30;
export const EXACTA = 110;
export const TRIFECTA = 160;
export const SUPERFECTA = 210;
export const SUPERHIGHFIVE = 260;
export const DAILYDOUBLE = 310;
export const PICK3 = 330;
export const PICK4 = 340;
export const PICK5 = 350;
export const PICK6 = 360;
export const PICK9 = 390;


enum Odds {
  ONE_NINTH = '1/9', // Muy favorito
  ONE_FIFTH = '1/5',
  TWO_FIFTHS = '2/5',
  ONE_HALF = '1/2',  // Equilibrio hacia favorito, pero con mejores retornos
  THREE_FIFTHS = '3/5',
  FOUR_FIFTHS = '4/5',
  ONE_ONE = '1/1',   // Equivalente a Evens
  SIX_FIFTHS = '6/5', // Ligeramente no favorito
  SEVEN_FIFTHS = '7/5',
  THREE_HALVES = '3/2', // Mejores retornos, menos favorito
  TWO_ONE = '2/1',   // Duplica la apuesta
  FIVE_TWO = '5/2',
  THREE_ONE = '3/1', // Triplica la apuesta
  FOUR_ONE = '4/1',  // Cuadruplica la apuesta
  FIVE_ONE = '5/1',  // Quintuplica la apuesta
  TEN_ONE = '10/1',  // Gran no favorito, retorna diez veces la apuesta
  TWENTY_ONE = '20/1',// Retorna veinte veces la apuesta
  FIFTY_ONE = '50/1', // Retorna cincuenta veces la apuesta, muy poco probable
  ONE_HUNDRED_ONE = '100/1', // Extremadamente improbable, grandes retornos
  F1 = 'F1', // Favorito
  F2 = 'F2', // Favorito
  F3 = 'F3', // Favorito
}

export const OPTIONS_ODDS = {
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  ONE_NINTH: '1/9', // Muy favorito
  ONE_FIFTH: '1/5',
  TWO_FIFTHS: '2/5',
  ONE_HALF: '1/2',  // Equilibrio hacia favorito, pero con mejores retornos
  THREE_FIFTHS: '3/5',
  FOUR_FIFTHS: '4/5',
  ONE_ONE: '1',   // Equivalente a Evens
  SIX_FIFTHS: '6/5', // Ligeramente no favorito
  SEVEN_FIFTHS: '7/5',
  THREE_HALVES: '3/2', // Mejores retornos, menos favorito
  TWO_ONE: '2',   // Duplica la apuesta
  FIVE_TWO: '5/2',
  THREE_ONE: '3', // Triplica la apuesta
  FOUR_ONE: '4',  // Cuadruplica la apuesta
  FIVE_ONE: '5',  // Quintuplica la apuesta
  TEN_ONE: '10',  // Gran no favorito, retorna diez veces la apuesta
  TWENTY_ONE: '20',// Retorna veinte veces la apuesta
  FIFTY_ONE: '50', // Retorna cincuenta veces la apuesta, muy poco probable
  ONE_HUNDRED_ONE: '99', // Extremadamente improbable, grandes retornos

}