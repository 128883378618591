import { Button, ButtonProps } from "antd"
import { Link } from "react-router-dom"
export interface propsButtoGoBack {
    title: string
    path: Function
} 

type CustomButton = ButtonProps & React.RefAttributes<HTMLElement> & propsButtoGoBack

export const ButtonGo = ({title, path, ...extra}: CustomButton) => {

    return <>
        <Button
        {...extra}
        type="primary"
        >
            <Link to={path()}>
               {title}
            </Link>
        </Button>
    </>
}