import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import AuthReducer from './reducers/auth';

import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';
import MessageSystemReducer from './reducers/message-system';
import RacecourseReducer from './reducers/racecourse';
import ProviderRacecourseReducer from './reducers/provider-racecourse';
import RaceReducer from './reducers/race';
import ResultReducer from './reducers/result';
import BlackItemReducer from './reducers/black-item';
import RacecourseEventReducer from './reducers/racecourse-event';
const reducers = combineReducers({
    auth: AuthReducer,
    messageSystem: MessageSystemReducer,
    racecourse: RacecourseReducer,
    providerRacecourse: ProviderRacecourseReducer,
    race: RaceReducer,
    result: ResultReducer,
    blackItem: BlackItemReducer,
    racecourseEvent: RacecourseEventReducer,
})

export const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch