import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authLogin, authLogout } from '../redux/actions/auth'
import { RootState } from '../redux/store'
import { PATH_AUTH } from '../Router'
import {checkUserAuth} from '../services/auth.service'


import { useHistory, useLocation } from 'react-router-dom'

export const AuthCheck = () => {
    console.log('que hace')
    
    const dispatch = useDispatch()
    const [loading, setLoading] = useState (true)
    
    
    useEffect(() => {
        checkUserAuth()
        .then(({id, name, username, rol})=>{
            dispatch(authLogin(id, name, username, rol))
            setLoading(false)
        })
        .catch((error)=> {
            if(error.statusCode === 401) {
                dispatch(authLogout())
            }
            setLoading(false)
        })
    },[])

    let location = useLocation();
    const history = useHistory();
    const userData = useSelector<RootState>(state => (state.auth  )) as {name: string, username: string} | {} 
    useEffect(() => {
        console.log('ejecutando', userData)
        if(loading === false && location && location.pathname !== PATH_AUTH && Object.keys (userData).includes('username') === false ) {
            console.log(["pageview", location.pathname]);
            history.push(PATH_AUTH)
        } else if (loading === false &&  location && location.pathname === PATH_AUTH && Object.keys (userData).includes('username') === true ) {
            console.log('ejecutando home')
            history.push('/')
        }

    }, [ loading ]);

    return <>
    </>
}