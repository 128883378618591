import { Button, Card, Col, List, Modal, Row, Tabs } from "antd";

import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RaceItemList } from "../../Components/RaceItemList";
import { ResultItemList } from "../ResultItemList";
import { messageSystemPush } from "../../redux/actions/message-system";
import { raceStatusSetList } from "../../redux/actions/race";
import { resultAddMoreList, resultSetList } from "../../redux/actions/result";
import { RootState } from "../../redux/store";
import { createResultsFromProvider, deleteResults, getPendingResultsFromProvider, getRacesStatus, RACECOURSE_EVENT_RACE_STATUS, RaceInfoStatus } from "../../services/racecourse-race.service";
import { getResultList, ResultItem } from "../../services/result.service";
import { IRacecourseRaceProviderResult } from "../../services/interfaces/racecourse-race-result-provider-pending";
import { ResultItemProviderPendingList } from "../ResultItemProviderPendingList";

import { ResultItemListDetail } from "../ResultItemListDetail";


export const DashboardRaces = () => {
    const [racesOpen, setRacesOpen] = useState<RaceInfoStatus[]>([])
    const [racesStandBy, setRacesStandBy] =useState<RaceInfoStatus[]>([])
    const {races} = useSelector<RootState>(state => (state.race)  ) as {races: RaceInfoStatus[]}
    const {data, pageSize, current, total} = useSelector<RootState>(state => (state.result)  ) as {data: any[], pageSize: number, current: number, total: number}

    const [resultsProviderPending, setResultsProviderPending] = useState<IRacecourseRaceProviderResult[]>([])
    const [isLoadingResultsProviderPending, setIsLoadingResultsProviderPending] = useState<boolean>(false);

    const [isVisibleResult, setIsVisibleResult] = useState<boolean>(false);
    const [resultSelected, setResultSelected] = useState<ResultItem | null>(null);
    const [isLoadingProcessResult, setIsLoadingProcessResult] = useState<boolean>(false);
    const handleGetResult = async (result: ResultItem) => {
        try {
          setResultSelected(result);
          setIsVisibleResult(true);
        } catch (error) {
          dispatch(messageSystemPush(error));
        }
    
      }
    
    const cleanResult = () => {
        setResultSelected(null);
        setIsVisibleResult(false);
    }

    const dispatch = useDispatch()

    let interval: any;
    let intervalRaces: any;
    useEffect(()=> {
        loadData()
        interval = setInterval(() => {
            loadPendingResults ();
        }, 10000);

        intervalRaces = setInterval(() => {
            loadData()
        }, 5000);

        return () => {
            clearInterval(interval)
            clearInterval(intervalRaces)
        }
    }, [])

    useEffect(()=> {
        const racesOpen = races.filter(item => item.status === RACECOURSE_EVENT_RACE_STATUS.OPEN)
        .sort((a, b) => {
            if(a.post_time < b.post_time) {
                return -1;
            } else if(a.post_time > b.post_time) {
                return 1;
            }
            return 0;
        
        })
        const racesStandBy = races.filter(item => item.status === RACECOURSE_EVENT_RACE_STATUS.STAND_BY)
        .sort((a, b) => {
            if(a.post_time < b.post_time) {
                return -1;
            } else if(a.post_time > b.post_time) {
                return 1;
            }
            return 0;
        })
        setRacesOpen(racesOpen);
        setRacesStandBy(racesStandBy)
    }, [races])

    const loadPendingResults = async () => {
        if(isLoadingResultsProviderPending) return;
        setIsLoadingResultsProviderPending(true)
        try {
            const data = await getPendingResultsFromProvider();
            

            let change = false;

            if(data.length === resultsProviderPending.length) {
                for (const item of data) {
                    const found = resultsProviderPending.find(item2 => item2._id === item._id)
                    if(!found) {
                        change = true
                        break;
                    }



                    if(found?.provider_results.length !== item.provider_results.length) {
                        change = true
                        break;
                    }

                    for (const item3 of found?.provider_results) {
                        const found2 = item.provider_results.find(item4 => item4._id === item3._id)
                        if(!found2) {
                            change = true
                            break;
                        }
                    }
                }

                for (const item of resultsProviderPending) {
                    const found = data.find(item2 => item2._id === item._id)
                    if(!found) {
                        change = true
                        break;
                    }

                    if(found?.provider_results.length !== item.provider_results.length) {
                        change = true
                        break;
                    }

                    for (const item3 of found?.provider_results) {
                        const found2 = item.provider_results.find(item4 => item4._id === item3._id)
                        if(!found2) {
                            change = true
                            break;
                        }
                    }
                }
            } else {
                change = true;
            }

            if(change) {
                const sorted = data.sort((a, b) => {
                    if(a.provider_results.length > b.provider_results.length) {
                        return -1;
                    } else if(a.provider_results.length < b.provider_results.length) {
                        return 1;
                    }
                    return 0;

                })
                setResultsProviderPending(sorted)
            }


        } catch (error) {
            console.error('error', error)
        } finally {
            setIsLoadingResultsProviderPending(false)
        }
    }




    const loadData = async () => {
        try {

            getRacesStatus()
            .then(races => dispatch(raceStatusSetList(races)))
            .catch(error => dispatch(messageSystemPush(error)))

            getResultList()
            .then(data => dispatch(resultSetList(data)))
            .catch(error => dispatch(messageSystemPush(error)))


        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
        }
    }

    const loadingMoreResultItems = async () => {
        try {
            const lastId = data[data.length - 1]?._id
            const dataResponse = await getResultList(lastId)
            dispatch(resultAddMoreList(dataResponse))
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
        }
    }


    const handleChangeResultProvider = async (providerResultId: string, isOk : boolean) => {
        console.log('handleChangeResultProvider', providerResultId, isOk)
        await loadPendingResults();
    }

    const handleDelete = async (id: string) => {
        try {
            setIsLoadingProcessResult(true)
            const result = await deleteResults(id);
            handleChangeResultProvider(id, true)
        }
        catch (error) {
            console.error(error)
        } finally {
            setIsLoadingProcessResult(false)
        }

    }

    const handleCreateFromProvider = async (id: string) => {
        try {
            setIsLoadingProcessResult(true)
            const result = await createResultsFromProvider(id);
            await handleChangeResultProvider(id, true)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoadingProcessResult(false)
        }
    }

    const gridConfig = {
        gutter: 16,
        column: 2,
    }


    return <>
            <Content style={{padding: '.3em'}}>
            <Modal
            title="Resultados"
            visible={isVisibleResult}
            onOk={() => cleanResult()}
            onCancel={() => cleanResult()}
            cancelText="Cancelar"
            okText="Ok"
        >
          {resultSelected && <ResultItemListDetail result={resultSelected} /> }
      </Modal>

        <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{minHeight: '56vh', maxHeight: '56vh',  overflow: 'auto',  overflowX: 'hidden'}}>
                    <h3>Carreras Abiertas</h3>
                    <List
                        grid={gridConfig}
                        itemLayout="vertical"
                        dataSource={racesOpen}
                        renderItem={(item: RaceInfoStatus) => (
                            <RaceItemList race={item}></RaceItemList>
                        )}
                    />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{maxHeight: '20vh' ,  overflow: 'auto',  overflowX: 'hidden'}}>
                    <h3>Carreras pendientes por activar</h3>
                    <List
                        grid={gridConfig}
                        itemLayout="horizontal"
                        dataSource={racesStandBy}
                        renderItem={(item: RaceInfoStatus) => (
                            <RaceItemList race={item}></RaceItemList>
                        )}
                    />
                    </Col>

                </Row>
            </Col>



            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{maxHeight: '90vh', overflow: 'auto', overflowX: 'hidden'}}>
            <h3>Resultados Pendientes</h3>
            <List
                itemLayout="horizontal"
                
                dataSource={resultsProviderPending}
                renderItem={(item: IRacecourseRaceProviderResult) => (
                    <ResultItemProviderPendingList 
                    handleCreateFromProvider={handleCreateFromProvider}
                    handleDelete={handleDelete}
                    isLoading={isLoadingProcessResult}
                    key={item._id} raceData={item}></ResultItemProviderPendingList>
                )}
            />
            <h3>Resultados Aprobados</h3>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item: ResultItem) => (
                    <ResultItemList key={item._id} result={item} onGetResult={handleGetResult}></ResultItemList>
                )}
            />,

            </Col>
        </Row>

        </Content>
    </>
}