import { Button, Card, Select, Form } from "antd"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { messageSystemPush } from "../redux/actions/message-system"
import { racecourseSetItem, racecourseUpdateItemInListAll } from "../redux/actions/racecourse"
import { ProviderRacecourseItem } from "../services/provider-racecourse.service"
import { createRacecourse, linkRacecourseProvider, RacecourseItem, RACECOURSE_CLASS, RACECOURSE_TYPE, unlinkRacecourseProvider } from "../services/racecourse.service"
import { Popconfirm, message } from 'antd';



export const ProviderRacecoursePanelToUnlink = ({racecourseItem, provider}: {provider: string, racecourseItem: RacecourseItem} ) => {

    const [loading, setLoading ] = useState(false)
    const dispatch = useDispatch()


    const onFinish = () => {
        unLinkRacecourseToProvider(racecourseItem._id)
    }


    const unLinkRacecourseToProvider = async (racecourseId: string) => {
        try {
            setLoading(true)
            const response = await unlinkRacecourseProvider(racecourseId, {provider})
            dispatch(racecourseUpdateItemInListAll(response))
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }

    }

    return (<>
        <Card title={racecourseItem.name} >

                <Popconfirm
                    title="Confirma desvincular proveedor"
                    onConfirm={onFinish}
                    okText="Si"
                    cancelText="No"
                >

                    <Button type="primary" danger >Devincular</Button>

                </Popconfirm>


        </Card>
    </>)
}