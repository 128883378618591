import { Card, List, Table } from "antd";
import {
  BET_TYPES_CORE,
} from "../../services/result.service";
import { BetResultItem } from "../../interfaces/bet-result-item";
import { RacecourseEventRacePendingResult } from "../../services/interfaces/racecourse-event-race-pending-result";

export interface BasicResultInterface {
  runner_number: number;
  bet_results: {
    bet_id: number;
    wager_amount: number;
    payout_amount: number;
  }[];
}

interface BasicResultDataInterface {
  runner_number: number;
  win: string;
  place: string;
  show: string;
}

export const ResultPreviewList = ({
  betItemsResults,
  currentRace,
}: {
  betItemsResults: BetResultItem[];
  currentRace: RacecourseEventRacePendingResult;
}) => {
  const columnsBasicTypes = [
    {
      title: "N° Ejemplar",
      dataIndex: "runner_number",
      key: "runner_number",
    },
    {
      title: "Win",
      dataIndex: "win",
      key: "win",
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
    },
    {
      title: "Show",
      dataIndex: "show",
      key: "show",
    },
  ];

  const columnsExotics = [
    {
      title: "Bet",
      dataIndex: "bet_name",
      key: "bet_name",
    },
    {
      title: "Combinacion",
      dataIndex: "runners",
      key: "runners",
    },
    {
      title: "Costo",
      dataIndex: "wager_amount",
      key: "wager_amount",
    },
    {
      title: "Dividendo",
      dataIndex: "payout_amount",
      key: "payout_amount",
    },
  ];

  const getBasicBets = () => {
    return betItemsResults
      .filter((item) => item.bet_id >= 10 && item.bet_id <= 30)
      .reduce((acc: BasicResultInterface[], item: BetResultItem) => {
        let itemRegistered: BasicResultInterface = acc.find(
          (subItem: BasicResultInterface) =>
            subItem.runner_number === +item.combinations
        ) as BasicResultInterface;
        if (itemRegistered !== undefined) {
          const betResult = {
            bet_id: item.bet_id,
            wager_amount: item.wager_amount,
            payout_amount: item.payout_amount,
          };
          return [
            ...acc.filter(
              (subItem: BasicResultInterface) =>
                subItem.runner_number !== +item.combinations
            ),
            {
              ...itemRegistered,
              bet_results: [...itemRegistered.bet_results, betResult],
            },
          ];
        } else {
          console.log("item", item);
          const newItem: BasicResultInterface = {
            runner_number: +item.combinations,
            bet_results: [
              {
                bet_id: item.bet_id,
                wager_amount: item.wager_amount,
                payout_amount: item.payout_amount,
              },
            ],
          };
          return [...acc, newItem];
        }
      }, [])
      .sort((a, b) => b.bet_results.length - a.bet_results.length)
      .map((item: BasicResultInterface) => {
        const items = item.bet_results;
        //.sort((a,b) => a.bet_id - b.bet_id)

        const itemPayout = [10, 20, 30].reduce((acc: any, subItem) => {
          const finded = items.find((i) => i.bet_id === subItem);
          let tag = "win";
          if (subItem === 20) {
            tag = "place";
          } else if (subItem === 30) {
            tag = "show";
          }
          if (finded) {
            return { ...acc, [tag]: finded.payout_amount.toString() };
          } else {
            return { ...acc, [tag]: "-" };
          }
        }, {});
        return {
          runner_number: item.runner_number,
          ...itemPayout,
        };
      });
  };

  return (
    <>
      <List.Item>
        <Card
          style={{ width: "100%" }}
          title={`${currentRace.racecourse_event.racecourse.name.toUpperCase()} - CARRERA: ${
            currentRace.number
          }`}
        >
          <Table
            pagination={false}
            columns={columnsBasicTypes}
            rowKey="runner_number"
            dataSource={getBasicBets()}
          >
            {" "}
          </Table>

          <Table
            pagination={false}
            columns={columnsExotics}
            rowKey="runners"
            dataSource={betItemsResults
              .filter((item) => item.bet_id > 30)
              .reduce((acc: any[], item) => {
                const betName =
                  BET_TYPES_CORE.find((i) => i.bet_id === item.bet_id)
                    ?.name_front || "";

                return [
                  ...acc,
                  {
                    runners: item.combinations,
                    wager_amount: item.wager_amount,
                    payout_amount: item.payout_amount,
                    bet_name: betName,
                  },
                ];
              }, [])}
          >
            {" "}
          </Table>
        </Card>
      </List.Item>
    </>
  );
};
