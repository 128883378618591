import { Button, Card, Col, DatePicker, List, Modal, Row, Tabs } from "antd";

import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messageSystemPush } from "../../redux/actions/message-system";
import { RootState } from "../../redux/store";
import { IRacecourseEvent, getRacecourseEventsByDate } from "../../services/racecourse-event.service";
import { IRaceEventRaceRunner, RaceEventRaceSimple, listByRacecourseEventId } from "../../services/racecourse-race.service";
import { racecourseEventRaceStatusSetList } from "../../redux/actions/racecourse-event";
import { RacecourseEventItem } from "../RacecourseEventItem";
import { raceListDateByEventId } from "../../redux/actions/race";
import { RacecourseEventRaceItem } from "../RacecourseEventRace.item";
import { RacecourseEventRaceRunner } from "../RacecourseEventRaceRunner";
import * as moment from 'moment-timezone';
import RacecourseEventTable from "../RacecourseEventTable";
import RacecourseEventRaceTable from "../RacecourseEventRaceTable";
import RacecourseEventRaceRunnerTable from "../RacecourseEventRaceRunnerTable";
import { DEFAULT_TIMEZONE } from "../../config";
import { ResultItem, getResultItemByRacecourseEventRaceId } from "../../services/result.service";
import { ResultItemListDetail } from "../ResultItemListDetail";


export const DashboardRacecourseEventsManagement = () => {
  const [date, setDate] = useState<moment.Moment>(moment.tz(DEFAULT_TIMEZONE));
  const { racecourseEventDate } = useSelector<RootState>((state) => (state.racecourseEvent)) as { racecourseEventDate: IRacecourseEvent[] }
  const { racesByDate } = useSelector<RootState>((state) => (state.race)) as { racesByDate: RaceEventRaceSimple[] }
  const [racecourseRaceSelected, setRacecourseRaceSelected] = useState<RaceEventRaceSimple | null>(null);
  const [racecourseEventSelected, setRacecourseEventSelected] = useState<IRacecourseEvent | null>(null);
  const dispatch = useDispatch()
  const [isVisibleResult, setIsVisibleResult] = useState<boolean>(false);
  const [resultSelected, setResultSelected] = useState<ResultItem | null>(null);
  
  useEffect(() => {
    const race = racesByDate.find(item => item._id === racecourseRaceSelected?._id);
    if(!race) {
      return;
    }
    setRacecourseRaceSelected(race)
  }, [racesByDate])

  useEffect(() => {
    setRacecourseRaceSelected(null);
    dispatch(racecourseEventRaceStatusSetList([]))
    dispatch(raceListDateByEventId([]))
    loadData()
  }, [date])

  const loadData = async () => {
    try {
      const racecourseEvents = await getRacecourseEventsByDate(date.format('YYYY-MM-DD'));
      console.log('events', racecourseEvents)
      
      const racecourseEventsSorted = racecourseEvents.sort((a, b) => {
        if (a.racecourse.name > b.racecourse.name) {
          return 1
        }
        if (a.racecourse.name < b.racecourse.name) {
          return -1
        }
        return 0
      })
      
      dispatch(racecourseEventRaceStatusSetList(racecourseEventsSorted))

    } catch (error) {
      dispatch(messageSystemPush(error))
    } finally {
    }
  }

  const loadDataRace = async (racecourseEventId: string) => {
    try {
      const racecourseEvent = racecourseEventDate.find(item => item._id === racecourseEventId);
      if(!racecourseEvent) {
        return;
      }
      setRacecourseEventSelected(racecourseEvent);
      setRacecourseRaceSelected(null);

      const racecourseEvents = await listByRacecourseEventId(racecourseEventId);
      console.log('races', racecourseEvents)
      
      const racecourseEventsSorted = racecourseEvents.sort((a, b) => a.number - b.number);
      
      dispatch(raceListDateByEventId(racecourseEventsSorted))

    } catch (error) {
      dispatch(messageSystemPush(error))
    } finally {
    }
  }

  const handleSelectRace = (racecourseEventRaceId: string) => {
    const race = racesByDate.find(item => item._id === racecourseEventRaceId);
    if(!race) {
      return;
    }
    setRacecourseRaceSelected(race)

  }

  const dateFormat = 'DD/MM/YYYY';

  const handleOnChangeDate = (date: moment.Moment | null) => {
    if(date) {
      setDate(date);
    }
  }

  const handleGetResult = async (racecourseRaceId: string) => {
    try {
      const response = await getResultItemByRacecourseEventRaceId(racecourseRaceId);
      setResultSelected(response);
      setIsVisibleResult(true);
    } catch (error) {
      dispatch(messageSystemPush(error));
    }

  }

  const cleanResult = () => {
    setResultSelected(null);
    setIsVisibleResult(false);
  }

  return <>
    <Content style={{ padding: '.3em' }}>
      <Modal
            title="Resultados"
            visible={isVisibleResult}
            onOk={() => cleanResult()}
            onCancel={() => cleanResult()}
            cancelText="Cancelar"
            okText="Ok"
        >
          {resultSelected && <ResultItemListDetail result={resultSelected} /> }
      </Modal>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DatePicker defaultValue={date} format={dateFormat} onChange={(value) => handleOnChangeDate(value)} />
        </Col>
      </Row>
      <Row >
        <Col xs={24} sm={24} md={24} lg={7} xl={7} style={{ padding: '.3em' }}>
          <h3>EVENTOS DEL DIA {date.format(dateFormat)}</h3>
          {/*<List
            itemLayout="horizontal"
            dataSource={racecourseEventDate}
            renderItem={(item: IRacecourseEvent) => (
              <RacecourseEventItem onSelectEvent={loadDataRace} racecourseEvent={item}></RacecourseEventItem>
            )}
            /> */}

          <RacecourseEventTable onSelectEvent={loadDataRace} racecourseEventDate={racecourseEventDate}></RacecourseEventTable>
        </Col>

        <Col xs={24} sm={24} md={24} lg={11} xl={11} style={{ padding: '.3em' }}>
          <h3>CARRERAS {racecourseEventSelected?.racecourse?.name?.toUpperCase()} </h3>
          {/*<List
            itemLayout="horizontal"
            dataSource={racesByDate}
            renderItem={(item: RaceEventRaceSimple) => (
              <RacecourseEventRaceItem handleSelectRace={handleSelectRace} raceEventRaceSimple={item}></RacecourseEventRaceItem>
            )}
            />,*/}
          <RacecourseEventRaceTable onGetResult={handleGetResult} onSelectEvent={handleSelectRace} racecourseEventRaceList={racesByDate}></RacecourseEventRaceTable>
        </Col>


        <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{ padding: '.3em' }}>
          <h3>EJEMPLARES  {racecourseEventSelected?.racecourse?.name?.toUpperCase()} - {racecourseRaceSelected?.number}   </h3>
          {racecourseRaceSelected?.runners &&
          <RacecourseEventRaceRunnerTable racecourseEventRace={racecourseRaceSelected} />}
        </Col>
      </Row>

    </Content>
  </>
}