import { RaceEventRaceSimple, RaceInfoStatus } from "../../services/racecourse-race.service";
import { RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS, RACECOURSE_EVENT_RACE_SET_LIST, RACECOURSE_EVENT_RACE_UPDATE_ITEM, RACECOURSE_EVENT_RACE_DATE_GET_LIST, RACECOURSE_EVENT_RACE_UPDATE_STATUS, RACECOURSE_EVENT_RACE_RUNNER_UPDATE_STATUS } from "../action-types";



export const raceStatusSetList = (races: RaceInfoStatus[]) => ({
    type: RACECOURSE_EVENT_RACE_SET_LIST,
    payload: {races} 
})


export const raceSetNewRaceToList = (race: RaceInfoStatus) => ({
    type: RACECOURSE_EVENT_RACE_UPDATE_ITEM,
    payload: {race}
})


//RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS

export const raceStatusSetMoreItems = (races: RaceInfoStatus[]) => ({
    type: RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS,
    payload: {races} 
})


export const raceListDateByEventId = (racesByDate: RaceEventRaceSimple[]) => ({
    type: RACECOURSE_EVENT_RACE_DATE_GET_LIST,
    payload: {racesByDate}
})

export const updateStatusRacecourseEventRace = (race: RaceEventRaceSimple) => ({
    type: RACECOURSE_EVENT_RACE_UPDATE_STATUS,
    payload: {race} 
})

export const updateStatusRacecourseEventRaceRunner = (race: RaceEventRaceSimple) => ({
    type: RACECOURSE_EVENT_RACE_RUNNER_UPDATE_STATUS,
    payload: {race}
});