import { Button, Card, List, Space, Table } from "antd"
import { useEffect, useState } from "react"
import { RaceInfoStatus } from "../services/racecourse-race.service"
import { ResultItem, Betresult, BET_TYPES_CORE } from "../services/result.service"
import { Typography } from 'antd';

const { Title } = Typography;
export interface BasicResultInterface {
    runner_number: number
    bet_results: {
        bet_id: number
        wager_amount: number
        payout_amount: number
    }[]

}

interface BasicResultDataInterface {
    runner_number: number
    win: string
    place: string
    show: string
}
interface ResultListItemProps {
  onGetResult:  (result: ResultItem) => Promise<void>;
  result: ResultItem;
}

export const ResultItemList = ({ result, onGetResult }: ResultListItemProps) => {


    return <>

        <List.Item>
            <Card style={{ width: '100%' }} title={``}>
               <Space align="center">
               <Title level={2}>{result.racecourse_event_race.number} - {result.racecourse_event_race.racecourse_event.racecourse.name.toUpperCase()}

                </Title>
                <Button type="primary" onClick={() => onGetResult(result)}>Ver Detalles</Button>
               </Space>


            </Card>

        </List.Item>
    </>
}