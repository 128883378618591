import { MESSSAGE_SYSTEM_PUSH, MESSAGE_SYSTEM_CLEAR } from "../action-types";




export const messageSystemPush = (err: any) => {
    let item = {
        statusCode: 500,
        message: 'Internal Error',
        error: 'Internal Error',
    }
    if(err.statusCode ) {
        item.error = err.error;
        item.statusCode = err.statusCode;
        item.message = err.message;
    }
    return {
        type: MESSSAGE_SYSTEM_PUSH,
        payload: item
    }

}



export const messageSystemClear = () => {
    return {
        type: MESSAGE_SYSTEM_CLEAR,
        payload: undefined,
    }

}