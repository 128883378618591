import { Header } from "./Header"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"



export const HeaderApp = ()=> {
    const {name, username} = useSelector<RootState>(state => (state.auth  )) as {name: string, username: string}

    return <>
        <Header name={name ? name : ''} username={username ? username: ''}></Header>
     </>
}