import { PROVIDER_RACECOURSE_DAY_LIST, RACECOURSE_UPDATE_ITEM_IN_LIST_ALL} from "../action-types"

const initialState = {
    provider: '',
    provider_racecourse_list : [],
}


const ProviderRacecourseReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case PROVIDER_RACECOURSE_DAY_LIST:
            const {data, provider} = action.payload
            return {...state, provider_racecourse_list: data, provider }

        default:
            return state
        break;
    }
}


export default ProviderRacecourseReducer