import { DAILYDOUBLE, PICK3, PICK4, PICK5, PICK6, PICK9, SUPERHIGHFIVE } from "../config";
import { BetResultItem } from "../interfaces/bet-result-item";
import { IRacecourseEventComplete } from "../services/interfaces/racecourse-event-race-complete";
import { RacecourseEventRacePendingResult } from "../services/interfaces/racecourse-event-race-pending-result";
import { IResultInput, IResultInputBetItem } from "../services/interfaces/racecourse-event-result-input";


const MAP_RACES_BET: {[key: number]: number} = {
  [DAILYDOUBLE]: 2,
  [PICK3]:3,
  [PICK4]:4,
  [PICK5]:5,
  [PICK6]:6,
  [PICK9]:9,
}

export const getResultsInputPayload = (
  date: string,
  positions: { position: number; number: number }[],
  betResultITems: BetResultItem[],
  raceEventRace: RacecourseEventRacePendingResult
): IResultInput => {

  const resultInputBetItems: IResultInputBetItem[] = [];
  for (const betResultItem of betResultITems) {
    if (betResultItem.bet_id <= SUPERHIGHFIVE) {
      resultInputBetItems.push({
        bet_id: betResultItem.bet_id,
        payout_amount: betResultItem.payout_amount,
        wager_amount: betResultItem.wager_amount,
        combinations: [{
          race_number: raceEventRace.number,
          runner_number: betResultItem.combinations.split('-').map((runnerNumber) => parseInt(runnerNumber)),
        }]
      });
    } else {
      const RaceInBet = MAP_RACES_BET[betResultItem.bet_id];
      const from = raceEventRace.number - RaceInBet + 1;
      const to = raceEventRace.number;
      const combinationList: {race_number: number, runner_number: number[]}[] = [];

      const combinations = betResultItem.combinations.split('-').map(item => +item);
      let index = 0;
      for (let numb = from; numb <= to; numb++) {
        combinationList.push({
          race_number: numb,
          runner_number: [combinations[index]],
        });
        index++;
      }
      resultInputBetItems.push({
        bet_id: betResultItem.bet_id,
        payout_amount: betResultItem.payout_amount,
        wager_amount: betResultItem.wager_amount,
        combinations: combinationList,
      });

    } 
  }

  return {
    date,
    positions: positions,
    racecourse_event_race_id: raceEventRace._id,
    scratches: [],
    bet_results: resultInputBetItems,
  }

};
