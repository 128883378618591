// ConfirmDialog.tsx

import React from 'react';
import { Modal } from 'antd';

interface ConfirmDialogProps {
    title: string;
    content: string;
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    title,
    content,
    isVisible,
    onConfirm,
    onCancel,
}) => {
    return (
        <Modal
            title={title}
            visible={isVisible}
            onOk={onConfirm}
            onCancel={onCancel}
            cancelText="Cancelar"
            okText="Confirmar"
        >
            {content}
        </Modal>
    );
}

export default ConfirmDialog;
