import axios from 'axios'
const connector = () => {
    const _axios = axios.create( {
        //baseURL: 'http://localhost:8080',
        //baseURL: 'https://sapi.weblyprinter.dev',
        baseURL: 'https://api.monitorhip.xyz',
    })

    const axiosHandleError = (error: any) => {
        if(error.response && error.response.data) {
            console.log('Im here')
            throw error.response.data
        } else {
            throw {statusCode: 500, message: 'Error desconocido', error: 'Error desconocido'}
        }
    }

    const getHeaders = () => {
        const token = window.localStorage.getItem('token-auth')
        let headers = {}

        if(token && token!= '') {
            headers = {...headers, Authorization: 'Bearer ' + token}
        }
        return headers
    }
    

    const post = async (url: string, payload: any)=> {
        try {
            const headers = getHeaders()
            const response = await _axios.post(url, payload, {headers})
            return response.data
        } catch (error) {
            console.log('error x', error.response)
            return axiosHandleError(error)
        }
    }

    
    const get = async (url: string)=> {
        try {
            const headers = getHeaders()
            const response = await _axios.get(url, {headers})
            return response.data
        } catch (error) {
            return axiosHandleError(error)
        }
    }

    const put = async (url: string, payload: any)=> {
        try {
            const headers = getHeaders()
            const response = await _axios.put(url, payload, {headers})
            return response.data
        } catch (error) {
            console.log('error x', error.response)
            return axiosHandleError(error)
        }
    }


    return {
        post,
        get,
        put
    }
}

export default connector()
