import connector from './connector.service'
import { PaginationDataResponse } from './interfaces/pagination.response.interface'

export const BET_TYPES_CORE= [
    {name_front: 'GANADOR',     bet_id: 10, name: 'Win'},
    {name_front: 'PLACE',       bet_id: 20, name: 'Place'},
    {name_front: 'SHOW',        bet_id: 30, name: 'Show'},
    {name_front: 'EXACTA',      bet_id: 110, name: 'Exacta'},
    {name_front: 'TRIFECTA',    bet_id: 160, name: 'Trifecta'},
    {name_front: 'SUPERFECTA',  bet_id: 210, name: 'Superfecta'},
    {name_front: 'SUPERHIFIVE', bet_id: 260, name: 'Superhighfive'},
    {name_front: 'DOUBLE',      bet_id: 310, name: 'Daily Double'},
    {name_front: 'PICK 3',      bet_id: 330, name: 'Pick 3'},
    {name_front: 'PICK 4',      bet_id: 340, name: 'Pick 4'},
    {name_front: 'PICK 5',      bet_id: 350, name: 'Pick 5'},
    {name_front: 'PICK 6',      bet_id: 360, name: 'Pick 6'},
    {name_front: 'PICK 9',      bet_id: 390, name: 'Pick 9'},
  ]
  



export const getResultList = async (lastId?: string):Promise<ResultItem[]> => {
    const query = lastId ? `?last_id=${lastId}` : ''
    const racecourseList:ResultItem[] = await connector.get(`/racecourse-event-race-result/list-paginated-complete${query}`)
    return racecourseList;

}



export const getResultItem = async (id: string):Promise<ResultItem> => {
    const racecourseItem:ResultItem = await connector.get(`/racecourse-event-race-result/find-by-id/complete/${id}`)
    return racecourseItem

}

export const getResultItemByRacecourseEventRaceId = async (id: string):Promise<ResultItem> => {
  const racecourseItem:ResultItem = await connector.get(`/racecourse-event-race-result/find-by-race-id/complete/${id}`)
  return racecourseItem

}

export interface RacecourseList {
    docs: ResultItem [],
    totalDocs: number,
    limit: number,
    totalPages: number,
    page: number,
    pagingCounter: number,
    hasPrevPage: boolean,
    hasNextPage: boolean,
    prevPage: number | null,
    nextPage: number | null,
}

export interface ResultItem {
    status: string;
    _id: string;
    date: string;
    bet_results: Betresult[];
    positions: Position[];
    racecourse_event_race: Racecourseeventrace;
    created_at: string;
    updated_at: string;
    __v: number;
  }
  
  interface Racecourseeventrace {
    date_close_finished: string;
    _id: string;
    racecourse_event: Racecourseevent;
    number: number;
    post_time: number;
  }
  
  interface Racecourseevent {
    _id: string;
    date: string;
    racecourse: Racecourse;
  }
  
  interface Racecourse {
    _id: string;
    name: string;
  }
  
  interface Position {
    number: number;
    position: number;
  }
  
  export interface Betresult {
    _id: string;
    bet_id: number;
    wager_amount: number,
    payout_amount: number,
    combinations: Combination[];
  }
  
  interface Combination {
    runner_number: number[];
    race_number: number;
  }