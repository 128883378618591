import { Col, Row, Select, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { ProviderRacecoursePanelToLink } from "../Components/ProviderRacecoursePanelToLink";
import { ProviderRacecoursePanelToUnlink } from "../Components/ProviderRacecoursePanelToUnlink";
import { messageSystemPush } from "../redux/actions/message-system";
import { providerRacecourseSetListDay } from "../redux/actions/provider-racecourse";
import { racecourseSetListAll } from "../redux/actions/racecourse";
import { RootState } from "../redux/store";
import { getProviderRacecourseDay, ProviderRacecourseItem } from "../services/provider-racecourse.service";
import { getRacecourseListAll, RacecourseItem } from "../services/racecourse.service";
const { TabPane } = Tabs;



export const ProviderRacecourseDay = () => {

    const {provider} = useParams() as {provider: string}
    const dispatch = useDispatch()
    const racecourseListAll = useSelector<RootState>(state => (state.racecourse.racecourse_list_all)  ) as RacecourseItem[]

    const providerRacecourseListDay = useSelector<RootState>(state => (state.providerRacecourse.provider_racecourse_list)  ) as ProviderRacecourseItem[]

    const [racecourseNotLinkedToProvider, setRacecourseNotLinkedToProvider] = useState<RacecourseItem[]>([])
    const [racecourseLinkedToProvider, setRacecourseLinkedToProvider] = useState<RacecourseItem[]>([])

    const [providerRacecurseFiltered, setProviderRacecurseFiltered] = useState<ProviderRacecourseItem[]>([])



    useEffect(()=> {
        loadData()
    },[provider])

    useEffect(()=> {
        console.log('esta cambiando', racecourseListAll)
        loadRacecourseNotLinkedToProvider()
        loadRacecourseLinkedToProvider()
        refreshDataLinked()
    },[racecourseListAll, providerRacecourseListDay])

    const loadData = async () => {
        try {
            //setLoading(true)
            const data = await getRacecourseListAll()
            const dataRacecourseDay = await getProviderRacecourseDay(provider)
            dispatch(racecourseSetListAll(data))
            dispatch(providerRacecourseSetListDay(provider, dataRacecourseDay))

        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            //setLoading(false)
        }
    }

    const refreshDataLinked = () => {
        console.log('refrescando data', providerRacecourseListDay)
        const items = providerRacecourseListDay.filter(item => {
            return racecourseListAll.find(subItem => {
                return subItem.providers.find(subSubItem => subSubItem.provider === item.provider && subSubItem.name === item.name) !== undefined
            }) === undefined
        })
        setProviderRacecurseFiltered (items)
    }


    const loadRacecourseNotLinkedToProvider = () => {
        console.log('ejecutando esto')
        const items = racecourseListAll.filter(item => {
            return item.providers.find(item => item.provider === provider) === undefined
        })
        setRacecourseNotLinkedToProvider(items)
    }

    const loadRacecourseLinkedToProvider = () => {
        console.log('ejecutando esto')
        const items = racecourseListAll.filter(item => {
            return item.providers.find(item => item.provider === provider) !== undefined
        })
        setRacecourseLinkedToProvider(items)
    }


    const itemsLinkedToProvider = racecourseLinkedToProvider.map((item) => {

        return (
            <Col key={item.name} xs={24} sm={24} md={24} lg={8} xl={8}>
                <ProviderRacecoursePanelToUnlink provider={provider} racecourseItem={item} ></ProviderRacecoursePanelToUnlink>

             </Col>
        )
        return <li key={item._id}>{ item.name }</li>
    })



    console.log('me estan ejecutando')
    const itemsProviderListDay = providerRacecurseFiltered.map((item) => {
        return (
            <Col key={item.name} xs={24} sm={24} md={24} lg={8} xl={8}>
                <ProviderRacecoursePanelToLink providerRacecourseItem={item} racecourseListNoLinked={racecourseNotLinkedToProvider} ></ProviderRacecoursePanelToLink>

            </Col>
        
        )
    })
/*        <h1>Hola mi interfaz {provider}</h1>
        {items}
        <br/>
        {itemsNotLinkedToProvider}

        <br />
        {itemsLinkedToProvider}

        <br/>-->*/


    return <>

<Content style={{padding: '.3em', height:'100hv', overflow:'visible'}}>


    <Row>
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
    <Tabs defaultActiveKey="1" >
        <TabPane tab="Hipodromos disponibles para vincular" key="1">
            <Row>
                {itemsProviderListDay}
            </Row>

        </TabPane>
        <TabPane tab="Hipodromos vinculados" key="2">
            <Row>
                {itemsLinkedToProvider}
            </Row>
        </TabPane>
    </Tabs>

</Col>

    </Row>
</Content>

        

    </>
}





