import { Button } from "antd"
import { useHistory } from "react-router-dom"

export interface propsButtoGoBack {
    disabled: boolean
    style?: React.CSSProperties
}


export const ButtonGoBack = (props: propsButtoGoBack) => {

    const history = useHistory()

    const handleGoBack = () => {
        history.goBack()
    }
    

    return <>
        <Button
        disabled={props.disabled}
        danger
        type="primary"
        onClick={handleGoBack}
        style={{...props.style}}
        >
            Volver Atras
        </Button>
    </>
}