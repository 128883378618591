import { Button, Col, Row, Space, Form, Input, Select, Divider, } from "antd"

import { Content } from "antd/lib/layout/layout"
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom"
import { messageSystemPush } from "../redux/actions/message-system";
import { racecourseSetItem } from "../redux/actions/racecourse";
import { createRacecourse, RACECOURSE_CLASS, RACECOURSE_TYPE } from "../services/racecourse.service";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { xs: {span: 24, offset: 0}, lg: {span: 12, offset: 6} },
  };

export const RacecourseNew = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const onFinish = async (data: any)=> {
        try {
            setLoading(true)
            const racecourse = await createRacecourse(data)
            dispatch(racecourseSetItem(racecourse))
            setLoading(false)
            console.log('data', data)  
            goRacecourse()
        } catch (error) {
            console.log('error', error)
            setLoading(false)
            dispatch(messageSystemPush(error))
        }

    }
    
    const goRacecourse = () => {
        history.push('/racecourse')
    }
    
    const onFinishFailed = () => console.error

    const optionsRacecourseClass = Object.values(RACECOURSE_CLASS).map(item => {
        return (
            <Select.Option key={item}  value={item}>{item}</Select.Option>
        )
    })


    
    const optionsRacecourseType = Object.values(RACECOURSE_TYPE).map(item => {
        return (
            <Select.Option key={item} value={item}>{item}</Select.Option>
        )
    })

    return <>
        <Content style={{ padding: '.3em' }}>

            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    remember: true,
                    racecourse_class: RACECOURSE_CLASS.NO_CLASS,
                    type: RACECOURSE_TYPE.AMERICAN,
                  }}
            >

                <Row>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Button block type="primary" danger>
                            Cancelar

                    </Button>
                    </Col>

                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 8 }}>
                        <Button block type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>
                    </Col>

                </Row>

                <Divider></Divider>

                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Por favor ingresa un nombre de hipodromo!' }]}
                >
                    <Input size="large" placeholder="Nombre de hipodromo" />
                </Form.Item>

                <Form.Item name="racecourse_class">
                    <Select size="large" >
                        {optionsRacecourseClass}
                    </Select>
                </Form.Item>

                <Form.Item name="type" >
                    <Select size="large"  >
                        {optionsRacecourseType}
                    </Select>
                </Form.Item>
            </Form>
        </Content>
    </>
}