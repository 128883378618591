import { Button, Card, List } from "antd";
//import TimeAgo from "javascript-time-ago"
import { useEffect, useState } from "react";
import {
  RACECOURSE_EVENT_RACE_STATUS,
  RaceInfoStatus,
  getRacesStatus,
  updateStatusById,
} from "../services/racecourse-race.service";
//import es from 'javascript-time-ago/locale/es'
//TimeAgo.addDefaultLocale(es)

import us from "javascript-time-ago/locale/es-US";
import {
  raceStatusSetList,
  updateStatusRacecourseEventRace,
} from "../redux/actions/race";
import { useDispatch } from "react-redux";
import { messageSystemPush } from "../redux/actions/message-system";
import { CheckCircleOutlined } from "@ant-design/icons";

//TimeAgo.addDefaultLocale(us)

//const timeAgo = new TimeAgo()

export const RaceItemList = ({ race }: { race: RaceInfoStatus }) => {
  const dispatch = useDispatch();
  const calculateTimeLeft = () => {
    let inverse = false;
    let difference = race.post_time - +new Date();
    if (race.post_time < +new Date()) {
      inverse = true;
      difference = +new Date() - race.post_time;
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    const displayHour = hours < 10 ? "0" + hours : hours + "";
    const displayMinutes = minutes < 10 ? "0" + minutes : minutes + "";
    const displaySeconds = seconds < 10 ? "0" + seconds : seconds + "";
    return (
      (inverse === true ? "-" : "") +
      `${displayHour}:${displayMinutes}:${displaySeconds}`
    );
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });
  const handleFinishRace = async (status: string) => {
    try {
      const racecourseEvents = await updateStatusById(race._id, status);
      dispatch(updateStatusRacecourseEventRace(racecourseEvents));
      const races = await getRacesStatus();
      dispatch(raceStatusSetList(races));
    } catch (error) {
      dispatch(messageSystemPush(error));
    } finally {
    }
  };

  const styleByTime  = (time: number) => {
    if(time <= 0) {
      return { color: 'red', 'FontWeight': 'bold', 'font-size': '20px'}
    } else if(time < 4) {
      return { color: 'orange', 'FontWeight': 'bold', 'font-size': '20px'}
    }
    return {
      color: 'green', 'FontWeight': 'bold', 'font-size': '20px'
    }

  }

  const lastUpdateHaveMoreThan2Minutes = (lastTime: string) => {
    const now = new Date();
    const lastUpdate = new Date(lastTime);
    const difference = now.getTime() - lastUpdate.getTime();
    const minutes = Math.floor((difference / 1000) / 60);
    return minutes > 2;
  }

  return (
    <>
      <List.Item>
        <Card
          style={{ width: "100%" }}
          title={`${ race.number } - ${race.racecourse_event.racecourse.name.toUpperCase()} `}
        >
          <span>{race.racecourse_event.status === 'active' ? 'ACTIVO' : 'INACTIVO'}</span> <br />
          <span style={styleByTime(race.minutes_to_start)} >{`MINUTOS SALIDA: ${race.minutes_to_start}` }</span>
          {race.status === RACECOURSE_EVENT_RACE_STATUS.OPEN && (
            <Button
              danger
              type="primary"
              onClick={() =>
                handleFinishRace(RACECOURSE_EVENT_RACE_STATUS.FINISHED)
              }
              icon={<CheckCircleOutlined />}
            >
              FINALIZAR
            </Button>
          )}
          {race.status === RACECOURSE_EVENT_RACE_STATUS.STAND_BY && (
            <Button
              
              type="primary"
              onClick={() =>
                handleFinishRace(RACECOURSE_EVENT_RACE_STATUS.OPEN)
              }
              icon={<CheckCircleOutlined />}
            >
              ACTIVAR
            </Button>
          )}
          <br />
          Ultima Actualizacion: {race.last_update}
          <br />
          {lastUpdateHaveMoreThan2Minutes(race.last_update) ? <span style={{backgroundColor: 'orange'}}>2min o mas sin actualizar</span> : null}
        </Card>
      </List.Item>
    </>
  );
};
