import { Button, Col, Row, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form"
import { Content } from "antd/lib/layout/layout"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { ButtonGoBack } from "../Components/ButtonGoBack";
import { messageSystemPush } from "../redux/actions/message-system";
import { racecourseSetItem } from "../redux/actions/racecourse";
import { editRacecourse, getRacecourseItem, RacecourseItem, RACECOURSE_CLASS, RACECOURSE_STATUS, RACECOURSE_TYPE } from "../services/racecourse.service";


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { xs: {span: 24, offset: 0}, lg: {span: 12, offset: 6} },
  };



const RacecourseEditForm = ({loading, submit , ...props}: RacecourseItem & {loading: boolean, submit: any}) => {
    const [form] = useForm<RacecourseItem>()


    const optionsRacecourseClass = Object.values(RACECOURSE_CLASS).map(item => {
        return (
            <Select.Option key={item}  value={item}>{item}</Select.Option>
        )
    })


    
    const optionsRacecourseType = Object.values(RACECOURSE_TYPE).map(item => {
        return (
            <Select.Option key={item} value={item}>{item}</Select.Option>
        )
    })

        
    const optionsRacecourseStatus = Object.values(RACECOURSE_STATUS).map(item => {
        return (
            <Select.Option key={item} value={item}>{item}</Select.Option>
        )
    })



    return <>
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={submit}
                initialValues={{...props}}
            >

                <Row>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <ButtonGoBack disabled={loading}  />
                    </Col>

                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 8 }}>
                        <Button block type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>
                    </Col>

                </Row>


                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Por favor ingresa un nombre de hipodromo!' }]}
                >
                    <Input size="large" placeholder="Nombre de hipodromo" />
                </Form.Item>

                <Form.Item name="racecourse_class">
                    <Select size="large" >
                        {optionsRacecourseClass}
                    </Select>
                </Form.Item>

                <Form.Item name="type" >
                    <Select size="large"  >
                        {optionsRacecourseType}
                    </Select>
                </Form.Item>

                
                <Form.Item name="status" >
                    <Select size="large"  >
                        {optionsRacecourseStatus}
                    </Select>
                </Form.Item>



</Form>

    </>
}



export const RacecourseEdit = () => {



    const param = useParams() as {id: string}
    const [racecourse, setRaceCourse] = useState<RacecourseItem>()
    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    useEffect(()=> {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            setLoading(true)
            const racecourseData = await getRacecourseItem(param.id)
            setRaceCourse(racecourseData)
            console.log(racecourse?.name,)

        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }
    }

    const updateForm = async (data: any) => {
        try {
            setLoading(true)
            const racecourse = await editRacecourse(param.id, data)
            dispatch(racecourseSetItem(racecourse))
            setLoading(false)
            console.log('data', data)  
            goRacecourse()
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }

    }

    const goRacecourse = () => {
        history.push('/racecourse')
    }



    return <>
        <Content style={{ padding: '.3em' }}>
            {racecourse && (<RacecourseEditForm loading={loading} {...racecourse} submit={updateForm} ></RacecourseEditForm>)} 

        </Content>

    </>
}