import connector from './connector.service'

export const sendPing = async (): Promise<boolean> => {
  try {
    const response: {status: boolean, error?: string} = await connector.put('/status-control/send-ping', {});
    return response?.status || false;
  } catch (error) {
    
    return false;
  }
}


export const updateStatus = async (status: boolean): Promise<boolean> => {
  try {
    const response: {status: boolean, error?: string} = await connector.put('/status-control/set-service-status/' + status, {});
    return response?.status || false;
  } catch (error) {
    
    return false;
  }
}

// if status is true then service_available and copilot_mode_enable are fetched
// if status is false then error is fetched
export const getStatus = async (): Promise<{status: boolean, error?: string, service_available?: boolean, copilot_mode_enable?: boolean, last_ping?: string, service_available_configured?: boolean}> => {
  try {
    const response: {status: boolean, error?: string, service_available?: boolean, copilot_mode_enable?: boolean} = await connector.get('/status-control');
    return response;
  } catch (error) {
    return {status: false, error: 'Error al obtener status'};
  }
}

