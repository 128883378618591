import { Tabs } from "antd";
import { DashboardBlackItem } from "../Components/Home/DashboardBlackItem";
import { DashboardRaces } from "../Components/Home/DashboardRaces";
import { DashboardRacecourseEventsManagement } from "../Components/Home/DashboardRacecourseEventsManagement";
import { RacecourseEventRaceResult } from "./RacecourseEventRaceResult";




export const Home = ()=> {

    const callback = (key: string) => {
        console.log(key);
    }

    return <> 

        <Tabs defaultActiveKey="1" onChange={callback}>
            <Tabs.TabPane tab="Panel de Carreras y Resultados" key="1">
                <DashboardRaces></DashboardRaces>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Gestionar Eventos y carreras" key="2">
                <DashboardRacecourseEventsManagement></DashboardRacecourseEventsManagement>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Crear Resultados" key="3">
                <RacecourseEventRaceResult></RacecourseEventRaceResult>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Errores detectados" key="4">
                <DashboardBlackItem></DashboardBlackItem>
            </Tabs.TabPane>
        </Tabs>

  

    </>
}