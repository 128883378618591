import { Button, Col, Row, Form, Input, Select, Table } from "antd"
import { useForm } from "antd/lib/form/Form"
import { Content } from "antd/lib/layout/layout"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ButtonGoBack } from "../Components/ButtonGoBack"
import { messageSystemPush } from "../redux/actions/message-system"
import { RootState } from "../redux/store"
import { getRacecourseItem, RacecourseItem, RACECOURSE_PROVIDERS, linkRacecourseProvider, unlinkRacecourseProvider } from "../services/racecourse.service"

const layout = {
    labelCol: { span: 0 },
    wrapperCol: { xs: {span: 24, offset: 0}, lg: {span: 24, offset: 0} },
};

export const RacecourseProviderManager = () => {

    const param = useParams() as {id: string}
    const [loading, setLoading] = useState(false)
    const [racecourse, setRaceCourse] = useState<RacecourseItem>()
    const [selectedKeys, selectKeys] = useState<React.Key[]>([])
    const [formAddProvider] = useForm()

    const dispatch = useDispatch()
    useEffect(()=> {
        console.log('ejecutando . . . 1')
        loadData()
    }, [])

    const loadData = async () => {
        try {
            setLoading(true)
            const racecourseData = await getRacecourseItem(param.id)
            setRaceCourse(racecourseData)
            
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally {
            setLoading(false)
        }
    }


    const handleAddProvider = async (data:any) => {
        try {
            if(racecourse) {
                setLoading(true)
                const racecourseData = await linkRacecourseProvider(racecourse._id, data)
                setRaceCourse(racecourseData)
            }
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally { 
            setLoading (false)
        }
    }

    const handleUnlinkProvider = async () => {
        try {
            if(racecourse) {
                setLoading(true)
                const key = selectedKeys[0]
                const racecourseData = await unlinkRacecourseProvider (racecourse._id, {provider: key.toString()})
                setRaceCourse(racecourseData)
                selectKeys([])
            }
        } catch (error) {
            dispatch(messageSystemPush(error))
        } finally { 
            setLoading (false)
        }
    }

    const onChangeSelectionRow = (rowsKeys:React.Key[], rows:any)=> {
        selectKeys(rowsKeys)
    }



    const columns = [
        {title: 'Proveedor', dataIndex: 'provider', sorter: true, width: '30%'},
        {title: 'Name', dataIndex: 'name', sorter: true, width: '15%'},
        {title: 'Code', dataIndex: 'code', sorter: true, width: '20%'},
        {title: 'Code alt', dataIndex: 'code_alt', sorter: true, width: '20%'},
        {title: 'Fecha registro', dataIndex: 'created_at', width: '20%', render: (item:string) => new Date(item).toDateString()},
    ]

        
    const optionsRacecourseProviders = Object.values(RACECOURSE_PROVIDERS).map(item => {
        return (
            <Select.Option key={item} value={item}>{item}</Select.Option>
        )
    })


    return <>


        <Content style={{padding: '.3em'}}>

        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <h1>{racecourse && racecourse?.name.toUpperCase()}</h1>
            </Col>
        </Row>

        <Row>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            <Form
                form={formAddProvider}
                {...layout}
                name="add-provider"
                onFinish={handleAddProvider}
                layout="inline"
                initialValues={{
                    provider: RACECOURSE_PROVIDERS.PROVIDER_A
                }}
            >
            <ButtonGoBack style={{marginRight: '10px'}} disabled={loading}  />
            <Form.Item name="provider" >
                <Select  >
                    {optionsRacecourseProviders}
                </Select>
            </Form.Item>

            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Por favor ingresa un name!' }]}
                >
                <Input placeholder="Name" />
            </Form.Item>


            <Form.Item
                name="code"
                rules={[{ required: true, message: 'Por favor ingresa un code!' }]}
                >
                <Input placeholder="Code" />
            </Form.Item>



            <Form.Item
                name="code_alt"
                rules={[{ required: true, message: 'Por favor ingresa un code alt!' }]}
                >
                <Input placeholder="Code Alt" />
            </Form.Item>



            <Button htmlType="submit"  type="primary" disabled={loading} style={{marginRight: '10px'}}>
                Agregar Proveedor
            </Button>

            <Button onClick={handleUnlinkProvider} disabled={selectedKeys.length <= 0 || loading} danger type="primary">
                Remover Proveedor
            </Button>
            


            </Form>

            </Col>

            </Row>


        <Table
        columns={columns}
        rowKey={(record) => record.provider}
        dataSource={racecourse?.providers}
        rowSelection={{onChange: onChangeSelectionRow, selectedRowKeys: selectedKeys, type: 'radio', columnWidth: '32px'}}
        loading={loading}
      />
        </Content>
    </>
}