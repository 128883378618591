import { IRacecourseEvent, IRacecourseEventChangeStatusResponse } from '../../services/racecourse-event.service'
import {RACECOURSE_EVENT_SET_LIST_BY_DATE, RACECOURSE_EVENT_SET_STATUS} from '../action-types'

export const racecourseEventRaceStatusSetList = (raceEvents: IRacecourseEvent[]) => ({
  type: RACECOURSE_EVENT_SET_LIST_BY_DATE,
  payload: {raceEvents} 
})

export const updateStatusRacecourseEvent = (raceEventStatus: IRacecourseEventChangeStatusResponse) => ({
  type: RACECOURSE_EVENT_SET_STATUS,
  payload: {raceEventStatus} 
})