
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import {useDispatch, useSelector} from 'react-redux'


import {Button, Layout, Input, Form} from 'antd'
import { AUTH_LOGIN } from '../redux/action-types';
import { authLogin, startLoginWithUsernameAndPassword } from '../redux/actions/auth';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { RootState } from '../redux/store';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { xs: {span: 24, offset: 0}, lg: {span: 12, offset: 6} },
  };
  const tailLayout = {
    wrapperCol: { xs: {span: 24, offset: 0}, lg: {span: 12, offset: 6} },
  };
  

export const Login = ()=> {

    const dispatch = useDispatch()
    const {name, username} = useSelector<RootState>(state => (state.auth  )) as {name: string, username: string}

    
    const history = useHistory();

    useEffect(() => {
        if(name && name != '') {
            history.push('/')
        }

    },[name])

    const onFinish = ({username, password}: any) => {
        console.log('Success:', username);
        dispatch(startLoginWithUsernameAndPassword(username, password))
      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
    
    return <> 


        <Layout style={{backgroundColor: 'white'}}>

            <Layout.Content style={{ margin: '24px 16px 0', textAlign: 'center' }}>
            <h1>Inicio de Sesion</h1>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >

                    <Form.Item

                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input size="large" placeholder="Nombre de usuario" prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item

                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input size="large" placeholder="Contraseña" type="password" prefix={<LockOutlined />} />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" block>
                        Iniciar
                        </Button>
                    </Form.Item>

                </Form>
            </Layout.Content >

        </Layout>



    </>
}