import { Menu } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AuthCheck } from "../Auth/AuthCheck";
import { HeaderApp } from "../Header/HeaderApp";

import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { ProviderRacecourseDay } from "../pages/ProviderRacecourseDay";
import { Racecourse } from "../pages/Racecourse";
import { RacecourseEdit } from "../pages/RacecourseEdit";
import { RacecourseNew } from "../pages/RacecourseNew";
import { RacecourseProviderManager } from "../pages/RacecourseProviderManager";
import { Sidebar } from "../Sidebar/Sidebar";
import { useDispatch } from "react-redux";
import { getRaceById } from "../services/racecourse-race.service";
import { raceSetNewRaceToList } from "../redux/actions/race";
import { getResultItem } from "../services/result.service";
import { resultAddItem } from "../redux/actions/result";
import { RacecourseEventCreate } from "../pages/RacecourseEventCreate";
import { RacecourseEventRaceResult } from "../pages/RacecourseEventRaceResult";
export const PATH_AUTH = "/login";

export const RouterApp = () => {
  const dispatch = useDispatch();

  //const eventSource = new EventSource("http://localhost:8080/sse");
  // DISABLE SERVER SSE
  /*
  const eventSource = new EventSource('https://api.monitorhip.xyz/sse');
  const TYPES_EVENT = {
    RACE_STATUS_CHANGED: "RACE_STATUS_CHANGED",
    RESULT_CREATED: "RESULT_CREATED",
  };

  interface EventDataInterface {
    type: string;
    date: number;
    id?: string;
  }

  const updateRace = async (id: string) => {
    try {
      const race = await getRaceById(id);
      dispatch(raceSetNewRaceToList(race));
    } catch (error) {
      console.log("error", error);
    }
  };

  const addResult = async (id: string) => {
    try {
      const result = await getResultItem(id);
      dispatch(resultAddItem(result));
    } catch (error) {
      console.log("error", error);
    }
  };

  if (eventSource !== null) {
    eventSource.onmessage = (message) => {
      const data: EventDataInterface = JSON.parse(message.data);

      switch (data.type) {
        case TYPES_EVENT.RACE_STATUS_CHANGED:
          return updateRace(data.id || "");
        case TYPES_EVENT.RESULT_CREATED:
          return addResult(data.id || "");
      }
      console.log("message new", data);
    };
  }
  */

  return (
    <>
      <Router>
        <AuthCheck></AuthCheck>
        <Layout>
          <Sidebar></Sidebar>
          <Content>
            <HeaderApp></HeaderApp>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>

              <Route exact path="/racecourse">
                <Racecourse />
              </Route>

              <Route exact path="/racecourse/new">
                <RacecourseNew />
              </Route>

              <Route exact path="/racecourse/edit/:id">
                <RacecourseEdit />
              </Route>

              <Route exact path="/racecourse/provider-manage/:id">
                <RacecourseProviderManager />
              </Route>

              <Route exact path="/provider/racecourse/:provider">
                <ProviderRacecourseDay />
              </Route>

              <Route exact path="/racecourse-event/create">
                <RacecourseEventCreate />
              </Route>

              <Route exact path="/racecourse-event-race/results/create">
                <RacecourseEventRaceResult></RacecourseEventRaceResult>
              </Route>
              <Route exact path={PATH_AUTH}>
                <Login />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Router>
    </>
  );
};
