import { useEffect, useRef, useState } from "react";
import { BetTypeCombinationOptions } from "../../interfaces/bet-types-combination-options.interface";
import { Button, Col, Form, Input, InputNumber, Row, Table } from "antd";
import { validateCombinationByBet } from "../../helpers/validate-combinations-by-bet";
import { useDispatch } from "react-redux";
import { messageSystemPush } from "../../redux/actions/message-system";
import ConfirmDialog from "../ConfirmDialog";
import { EXACTA, PLACE, SHOW, SUPERFECTA, TRIFECTA, WIN } from "../../config";
import { BetResultItem } from "../../interfaces/bet-result-item";
import { getDataByCombinations } from "../../helpers/make-combinations-exotic-basic";

type props = {
  betTypeCombinationOptions: BetTypeCombinationOptions[];
  positionSetter: { number: number; position: number }[];
  handleSendResults: (data: BetResultItem[]) => void;
  cleanToSelectPositions: () => void;
  betItemsResults: BetResultItem[];
};

const createNewDataItem = (
  betId: number,
  nameFront: string,
  combination: string,
  countIndex: number,
  waggerAmount: number
) => {
  return {
    key: new Date().getTime().toString() + countIndex,
    bet_id: betId,
    name_front: nameFront,
    combinations: combination,
    wager_amount: waggerAmount,
    payout_amount: 0,
  };
};

export const TableSetResults = ({
  betTypeCombinationOptions,
  positionSetter,
  betItemsResults,
  handleSendResults,
  cleanToSelectPositions,
}: props) => {
  const [data, setData] = useState<BetResultItem[]>([]);
  const [rowSelected, setRowSelected] = useState<BetResultItem | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('betItemsResults', betItemsResults)
    if(betItemsResults.length > 0) {
      setData(betItemsResults);
    } else {
      const newData: BetResultItem[] = getDataByCombinations(
        positionSetter,
        betTypeCombinationOptions
      );
      setData(newData);
    }

  }, [betTypeCombinationOptions, positionSetter, betItemsResults]);

  const handleSave = () => {
    let index = 1;
    const mapperCombinations = new Map<string, number>();
    for (const row of data) {
      const betType = betTypeCombinationOptions.find(
        (item) => item.bet_id === row?.bet_id
      );
      if (!betType) {
        dispatch(
          messageSystemPush({
            statusCode: 500,
            message: "Tipo de apuesta no encontrada",
            error: "Tipo de apuesta no encontrada",
          })
        );
        return;
      }
      const response = validateCombinationByBet(betType, row.combinations);

      if (!response.isValid) {
        dispatch(
          messageSystemPush({
            statusCode: 500,
            message: response.errorMessage + " Revisar fila " + index,
            error: response.errorMessage,
          })
        );
        return;
      }
      if (mapperCombinations.has(`${row.bet_id}:${row.combinations}`)) {
        dispatch(
          messageSystemPush({
            statusCode: 500,
            message:
              "Combinación duplicada " +
              row.combinations +
              " Revisar fila " +
              index,
            error: "Combinación duplicada " + row.combinations,
          })
        );
        return;
      }
      mapperCombinations.set(`${row.bet_id}:${row.combinations}`, 1);
      index++;
    }
    handleSendResults(data);
    console.log(data);
  };

  const handleChange = (
    key: string,
    column: keyof BetResultItem,
    value: string | number
  ) => {
    const newData = [...data];
    const row = newData.find((item) => item.key === key);
    if (row) {
      (row[column] as any) = value;
      setData(newData);
    }
  };

  const addAnotherBetResult = (key: string) => {
    const newData = [...data];
    const row = newData.find((item) => item.key === key);

    if (row) {
      const index = newData.indexOf(row);
      newData.splice(index + 1, 0, {
        key: new Date().getTime().toString() + newData.length,
        bet_id: row.bet_id,
        name_front: row.name_front,
        combinations: "",
        wager_amount: 0,
        payout_amount: 0,
      });
      setData(newData);
    }
  };

  const handleRemoveBet = () => {
    if (rowSelected) {
      const newData = [...data];
      const index = newData.indexOf(rowSelected);
      newData.splice(index, 1);
      setData(newData);
      setShowConfirmDialog(false);
    }
  };

  const confirmRemoveBet = (key: string) => {
    const row = data.find((item) => item.key === key);
    if (row) {
      setRowSelected(row);
      setShowConfirmDialog(true);
    }
  };

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, data.length);
  }, [data]);

  const handleKeyDown = (rowIndex: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && rowIndex < data.length - 1) {
      inputRefs.current[rowIndex + 1]?.focus();
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (+inputValue === 0) {
      event.target.select();
    }
  };

  const columns = [
    {
      title: "JUEGO",
      dataIndex: "name_front",
    },
    {
      title: "COMBINACIONES",
      dataIndex: "combinations",
      render: (_: any, record: BetResultItem) => (
        <Input
          value={record.combinations}
          onChange={(e) =>
            handleChange(record.key, "combinations", e.target.value)
          }
        />
      ),
    },
    {
      title: "COSTO BOLETO",
      dataIndex: "wager_amount",
      render: (_: any, record: BetResultItem) => (
        <InputNumber
          min={0}
          value={record.wager_amount}
          onChange={(value) => handleChange(record.key, "wager_amount", value)}
        />
      ),
    },
    {
      title: "DIVIDENDO",
      dataIndex: "payout_amount",
      render: (_: any, record: BetResultItem, index: number) => (
        <InputNumber
          ref={(el) => (inputRefs.current[index] = el)}
          min={0}
          value={record.payout_amount}
          onChange={(value) => handleChange(record.key, "payout_amount", value)}
          onKeyDown={handleKeyDown(index)}
          onFocus={handleFocus}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: BetResultItem) => {
        return (
          <>
            <Button onClick={() => confirmRemoveBet(record.key)}>QUITAR</Button>
            <Button onClick={() => addAnotherBetResult(record.key)}>
              AGREGAR {record.name_front}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Form component={false}>
      <ConfirmDialog
        title="Remover resultado de juego"
        isVisible={showConfirmDialog}
        onConfirm={handleRemoveBet}
        onCancel={() => setShowConfirmDialog(false)}
        content="¿Confirmas remover este resultado de evento?"
      ></ConfirmDialog>

      <Row justify="space-between">
        <Col>
          <Button
            onClick={() => cleanToSelectPositions()}
            style={{ marginLeft: ".2em" }}
            type="primary"
            htmlType="button"
          >
            Editar Posiciones
          </Button>
        </Col>
        <Col>
          <Button onClick={handleSave} type="primary">
            {" "}
            Siguiente{" "}
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};
