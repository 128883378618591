import { stat } from "fs"
import { ResultItem } from "../../services/result.service"
import { RACECOURSE_EVENT_RACE_RESULT_SET_LIST, RACECOURSE_EVENT_RACE_RESULT_ADD_ITEM, RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS} from "../action-types"

const initialState = {
    data: [],
    current: 1,
    pageSize: 20,
    total: 0
}


const ResultReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case RACECOURSE_EVENT_RACE_RESULT_SET_LIST:
            const {data, current, pageSize, total} = action.payload
            return {...state, data, current, pageSize, total }
            break;
        case RACECOURSE_EVENT_RACE_RESULT_ADD_LIST_ITEMS:
            const newData = action.payload.data.filter((item: ResultItem) => {
                const finded = state.data.find((stateItem: ResultItem) => stateItem._id === item._id)
                return finded === undefined
            })
            return {...state, data: [...state.data, ...newData], current: action.payload.current, pageSize: action.payload.pageSize, total: action.payload.total }
            break;
        case RACECOURSE_EVENT_RACE_RESULT_ADD_ITEM:
            const {result} = action.payload  as {result: ResultItem}
            let finded= false
            const results = state.data.map((item: ResultItem) => {
                if(result._id === item._id) {
                    finded = true
                    return result
                } else {
                    return item
                }
            })
            
            if(finded === false) {
                results.unshift(result)
            }

            if(results.length > 15) {
                //only keep first 15 items
                results.splice(15, results.length - 15)
            }
            

            return {...state, data: results}
            break;
        default:
            return state
    }
}


export default ResultReducer