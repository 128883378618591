import { BetTypeCombinationOptions } from "../interfaces/bet-types-combination-options.interface";



export const validateCombinationByBet = (betType: BetTypeCombinationOptions, inputData: string): {isValid: boolean, errorMessage?: string} => {
  //remove all spaces to input data
  inputData = inputData.replace(/\s/g, '');

  const { bet_id, combination_runners } = betType;
  // Segundo, validamos los números dentro de las combinaciones
  const combinationsToValidate = inputData.split('-');
  // Primero, validamos la longitud
  let expectedLength;
  switch (bet_id) {
    case 10:
      expectedLength = 1;
      break;
    case 20:
      expectedLength = 1;
      break;
    case 30:
      expectedLength = 1;
      break;
    case 110:
      expectedLength = 2;
      break;
    case 160:
      expectedLength = 3;
      break;
    case 210:
      expectedLength = 4;
      break;
    case 260:
      expectedLength = 5;
      break;
    case 310:
      expectedLength = 2;
      break;
    case 330:
      expectedLength = 3;
      break;
    case 340:
      expectedLength = 4;
      break;
    case 350:
      expectedLength = 5;
      break;
    case 360:
      expectedLength = 6;
      break;
    case 390:
      expectedLength = 9;
      break;
    default:
      return { isValid: false, errorMessage: "Tipo de apuesta no reconocido." };
  }

  if (combinationsToValidate.length !== expectedLength) {
    return { isValid: false, errorMessage: `Se esperaban ${expectedLength} combinaciones para este tipo de apuesta, pero se recibieron ${combinationsToValidate.length}.` };
  }

  const mapperRepeated  = new Map<string, number>();

  for (let i = 0; i < combination_runners.length; i++) {
    const runnersInCombination = combination_runners[i].combination_runners.map(runner => runner.number);
    const combinationToValidateForCombination = combinationsToValidate[i];

    if (!combinationToValidateForCombination) {
      return { isValid: false, errorMessage: `Se esperaba una combinación para la combinación ${i + 1}, pero no se recibió ninguna.` };
    }

    if(!runnersInCombination.includes(+combinationToValidateForCombination)) {
      return { isValid: false, errorMessage: `El número ${combinationToValidateForCombination} no es una opción válida para la combinación ${i + 1}.` };
    }

    if(bet_id < 300) {
      const isValid = inputData.split('-').filter(item => item !== '').length === Array.from(new Set(inputData.split('-'))).filter(item => item !== '').length;
      console.log(inputData.split('-'))
      console.log(Array.from(new Set(inputData.split('-'))))
      if(!isValid) {
        return { isValid: false, errorMessage: `No se pueden repetir números en una combinación.` };
      }
    }

  }



  return { isValid: true };
};
