import { DAILYDOUBLE, EXACTA, PICK3, PICK4, PICK5, PICK6, PICK9, PLACE, SHOW, SUPERFECTA, SUPERHIGHFIVE, TRIFECTA, WIN } from "../config";
import { ICombinationType } from "../interfaces/combination-type.interface";
import { IRacecourseEventComplete, IRacecourseEventCompleteRaceRunner } from "../services/interfaces/racecourse-event-race-complete";

// Definición de constantes para los tipos de apuestas

export function getRunnersForBetTypeCombination(raceNumber: number, betId: number, event: IRacecourseEventComplete): ICombinationType[] {
  let runnerCombinations: ICombinationType[] = [];

  const singleRaceBets = [WIN, PLACE, SHOW, EXACTA, TRIFECTA, SUPERFECTA, SUPERHIGHFIVE];

  if (singleRaceBets.includes(betId)) {
    const race = event.races[raceNumber - 1];
    runnerCombinations.push({race_number: race.number, combination_runners: race.runners.filter(runner => !runner.scratched)});
  } else if (betId === DAILYDOUBLE) {
    if (raceNumber > 1) {
      const race1 = event.races.find(r => r.number === raceNumber - 1)!;
      runnerCombinations.push({race_number: race1.number, combination_runners: race1.runners.filter(runner => !runner.scratched)});
    }
    const race2 = event.races.find(r => r.number === raceNumber)!;
    runnerCombinations.push({race_number: race2.number, combination_runners: race2.runners.filter(runner => !runner.scratched)});
  } else {
    const pickIdsMapping:{[key: number]: number} = {
      [PICK3]: 3,
      [PICK4]: 4,
      [PICK5]: 5,
      [PICK6]: 6,
      [PICK9]: 9
    };
    //if(!pickIdsMapping[betId]) throw new Error(`Bet type ${betId} not supported`);
    const pickNumber = pickIdsMapping[betId];
    if (pickNumber && raceNumber <= event.races.length - pickNumber + 1) {
      for (let i = 0; i < pickNumber; i++) {
        const race = event.races.find(r => r.number === raceNumber - pickNumber + 1 +i )!;
        runnerCombinations.push({race_number: race.number, combination_runners: race.runners.filter(runner => !runner.scratched)} );
      }
    }
  }

  return runnerCombinations;
}
