import { Table, Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IRacecourseEvent, updateRacecourseEventStatusById } from '../services/racecourse-event.service';
import { useDispatch } from 'react-redux';
import { messageSystemPush } from '../redux/actions/message-system';
import { RACECOURSE_STATUS } from '../services/racecourse.service';
import { updateStatusRacecourseEvent } from '../redux/actions/racecourse-event';
import { IRaceEventRaceRunner, RUNNER_STATUS, RaceEventRaceSimple, updateRunnerByStatusId } from '../services/racecourse-race.service';
import { updateStatusRacecourseEventRaceRunner } from '../redux/actions/race';

interface RacecourseEventTableProps {
  racecourseEventRace: RaceEventRaceSimple;
}

const RacecourseEventRaceRunnerTable: React.FC<RacecourseEventTableProps> = ({ racecourseEventRace }) => {
  const dispatch = useDispatch();
  

  const handleChangeStatus = async (runnerId: string) => {
    const racecourseEventRaceRunner = racecourseEventRace.runners.find(runner => runner._id === runnerId);
    if(!racecourseEventRaceRunner) return;


    const statusTarget = racecourseEventRaceRunner.scratched === true ? RUNNER_STATUS.ACTIVE : RUNNER_STATUS.SCRATCHED
    
    try {
      const racecourseEventsRaceResponse = await updateRunnerByStatusId(racecourseEventRace._id, racecourseEventRaceRunner._id, statusTarget);
      dispatch(updateStatusRacecourseEventRaceRunner(racecourseEventsRaceResponse))
    } catch (error) {
      dispatch(messageSystemPush(error))
    } finally {
    }
  }

  const getRowClassName = (record: IRaceEventRaceRunner) => {
    // Define tu condición aquí, he usado status como ejemplo
    return !record.scratched ? 'row-active' : 'row-inactive';
  };
  const columns: ColumnsType<IRaceEventRaceRunner> = [
    {
      title: 'Ejemplares',
      dataIndex: ['number', 'name'],
      key: 'number',
      render: (name: string, record: IRaceEventRaceRunner) => <span> {record.number} - {record.name.toUpperCase()} - [{record.odds}]</span>,
    },
    /*{
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // customize the status cell as needed
      render: (scratched: boolean) => {
        const statusTarget = scratched === true ? 'RETIRADO' : 'ACTIVO';
        return <span>{statusTarget}</span>
      },
    },*/
    {
      title: 'Actions',
      key: 'actions',
      render: (_text: string, record: IRaceEventRaceRunner) => {
        const buttonTitleToShow = record.scratched === true ? 'ACTIVAR' : 'RETIRAR';
        return (
          <Space>
            <Button onClick={() => handleChangeStatus(record._id)}>{buttonTitleToShow}</Button>
          </Space>
        );
      }
    },
  ];

  return (
    <Table pagination = {false} columns={columns} dataSource={racecourseEventRace.runners} rowKey="_id" rowClassName={getRowClassName} />
  );
};

export default RacecourseEventRaceRunnerTable;
