import { BlackItemInterface } from "../../services/black-item.service";
import { BLACK_ITEM_TODAY_ADD_ITEM, BLACK_ITEM_TODAY_ADD_LIST_ITEMS, BLACK_ITEM_TODAY_SET_LIST } from "../action-types";

export const blackItemTodaySetList = (data:any[]) => ({
    type: BLACK_ITEM_TODAY_SET_LIST,
    payload: { 
        data
    }
})

export const blackItemTodayAddItem = (result: BlackItemInterface) => ({
    type: BLACK_ITEM_TODAY_ADD_ITEM,
    payload: {result}
})

export const blackItemTodayAddMoreItems = (data:any[]) => ({
    type: BLACK_ITEM_TODAY_ADD_LIST_ITEMS,
    payload: { 
        data
    }
})


