import {AUTH_LOGIN, AUTH_LOGOUT} from '../action-types'
import {login} from '../../services/auth.service'
import { messageSystemPush } from './message-system'

export const startLoginWithUsernameAndPassword = (username:string, password: string) =>{
    return async (dispatch: any) => {
        try {
           
            const {id, name, rol} = await login(username, password)
            dispatch(authLogin(id, username, name, rol))
        } catch (error) {
            
            dispatch(messageSystemPush(error))

        }
    }
}

export const authLogin = (id: string, username: string, name: string, rol: string) => ({
    type: AUTH_LOGIN,
    payload: { 
        id, username, name, rol
    }
})



export const authLogout = () => ({
    type: AUTH_LOGOUT,
    payload: undefined
})

