import { ResultItem } from "../../services/result.service"
import { BLACK_ITEM_TODAY_SET_LIST, BLACK_ITEM_TODAY_ADD_LIST_ITEMS, BLACK_ITEM_TODAY_ADD_ITEM} from "../action-types"

const initialState = {
    data: [],
    current: 1,
    pageSize: 20,
    total: 0
}


const BlackItemReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case BLACK_ITEM_TODAY_SET_LIST:
            const {data, current, pageSize, total} = action.payload
            return {...state, data, current, pageSize, total }

        case BLACK_ITEM_TODAY_ADD_LIST_ITEMS:
            return {...state, data: [...state.data, ...action.payload.data], current: action.payload.current, pageSize: action.payload.pageSize, total: action.payload.total }

        case BLACK_ITEM_TODAY_ADD_ITEM:
            const {result} = action.payload  as {result: ResultItem}
            let finded= false
            const results = state.data.map((item: ResultItem) => {
                if(result._id === item._id) {
                    finded = true
                    return result
                } else {
                    return item
                }
            })
            
            if(finded === false) {
                results.unshift(result)
            }


            return {...state, data: results}

        default:
            return state
    }
}


export default BlackItemReducer