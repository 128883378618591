import { Button, Card, List } from "antd"
import { useEffect, useState } from "react"
import { BlackItemInterface, BLACK_ITEM_MODULE } from "../../services/black-item.service"

export const BlackListItem = ({ blackItem }: { blackItem: BlackItemInterface }) => {
    /*const calculateTimeLeft = () => {
        let inverse = false
        let difference =  (race.post_time -  +new Date())
        if(race.post_time < +new Date()) {
            inverse = true
            difference = (+new Date() - race.post_time )
        }


        
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((difference / 1000 / 60) % 60)
        const seconds = Math.floor((difference / 1000) % 60)
        
        const displayHour = hours < 10 ? '0'+ hours : hours + ''
        const displayMinutes = minutes < 10 ? '0'+ minutes : minutes + ''
        const displaySeconds = seconds < 10 ? '0'+ seconds : seconds + ''
        return (inverse === true ? '-': '') + `${displayHour}:${displayMinutes}:${displaySeconds}`
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


    useEffect(()=> {
        const timer =setTimeout(() => {
            setTimeLeft(calculateTimeLeft());

          }, 1000);

          return () => clearTimeout(timer);
    })*/

    const moduleByProblem = [
        {
            module: BLACK_ITEM_MODULE.RESULT_CREATE,
            description: 'Problema al cargar resultados de carrera'
        },
        {
            module: BLACK_ITEM_MODULE.EVENT_CREATE,
            description: 'Problema al crear evento (jornada) en hipodromo'
        }
    ]

    const moduleDescription = () => moduleByProblem.find(item => blackItem.module === item.module)?.description || ''


    return <>

        <List.Item>

            <Card style={{width: '100%'}} title={`${blackItem.module.toUpperCase()} `}>
                {moduleDescription}
                <br/>
                {`id: ${blackItem.item_id}`}
                <br/>
                {`TYPE: ${blackItem.type_item}`}
                <br/>
                Hora Evento: {new Date(blackItem.created_at).toLocaleTimeString()}
                <br/>
                Fecha Evento:  {new Date(blackItem.created_at).toLocaleDateString()}
                <br/>
                <Button> Intentar Reparar </Button>

            </Card>
            
        </List.Item>
    </>
}