import { useState } from "react";
import { RacecourseEventRacePendingResult } from "../services/interfaces/racecourse-event-race-pending-result";
import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment-timezone";
import { DEFAULT_TIMEZONE } from "../config";
import { createResults, getListPendingForResults } from "../services/racecourse-race.service";
import { getBetTypesToLoadResults } from "../helpers/get-bet-types-for-result-race";
import { getRacecourseEventCompleteById } from "../services/racecourse-event.service";
import { useDispatch } from "react-redux";
import { messageSystemPush } from "../redux/actions/message-system";
import { IRacecourseEventCompleteRaceRunner } from "../services/interfaces/racecourse-event-race-complete";
import { getRunnersForBetTypeCombination } from "../helpers/get-combination-runners-by-bet";
import { BetTypeCombinationOptions } from "../interfaces/bet-types-combination-options.interface";
import { TableSetResults } from "../Components/RacecourseEventRaceResult/TableSetResults";
import PositionSetter from "../Components/RacecourseEventRaceResult/PositionSetter";
import { BetResultItem } from "../interfaces/bet-result-item";
import { ResultPreviewList } from "../Components/RacecourseEventRaceResult/ResultPreview";
import { getResultsInputPayload } from "../helpers/get-results-input-payload";

const modes = {
  selectDate: "selectDate",
  selectRace: "selectRace",
  setPositions: "setPositions",
  setResults: "setResults",
  confirmResults: "confirmResults",
};

export const RacecourseEventRaceResult = () => {
  const [
    racecourseEventRacePendingResults,
    setRacecourseEventRacePendingResults,
  ] = useState<RacecourseEventRacePendingResult[]>([]);

  const [raceSelected, setRaceSelected] =
    useState<RacecourseEventRacePendingResult | null>(null);

  const dispatch = useDispatch();

  const [betTypesAvailable, setBetTypesAvailable] = useState<
    BetTypeCombinationOptions[]
  >([]);
  const [betItemResults, setBetItemResults] = useState<BetResultItem[]>([]);

  const [mode, setMode] = useState<string>(modes.selectDate);

  const [positions, setPositions] = useState<
    { number: number; position: number }[]
  >([]);

  const dateFormat = "DD/MM/YYYY";

  const [date, setDate] = useState<moment.Moment>(moment.tz(DEFAULT_TIMEZONE));

  const handleSubmit = () => {};

  const handleDateChange = (value: moment.Moment | null) => {
    if (value) {
      setDate(value);
    }
  };

  const cleanToSelectDate = () => {
    setRacecourseEventRacePendingResults([]);
    setRaceSelected(null);
    setBetTypesAvailable([]);
    setPositions([]);
    setBetItemResults([]);
    setMode(modes.selectDate);
  };

  const cleanToSelectRace = () => {
    setRaceSelected(null);
    setBetTypesAvailable([]);
    setPositions([]);
    setBetItemResults([]);
    setMode(modes.selectRace);
  };

  const cleanToSelectPositions = () => {
    setBetItemResults([]);
    setMode(modes.setPositions);
  };

  const selectDate = async () => {
    const results = await getListPendingForResults(date.format("YYYY-MM-DD"));
    console.log("results", results);
    setRacecourseEventRacePendingResults(results);
    if (results.length > 0) {
      setMode(modes.selectRace);
    }
  };

  const handleRacecourseRaceChange = (id: string) => {
    const race = racecourseEventRacePendingResults.find(
      (item) => item._id === id
    );
    setRaceSelected(race ? race : null);
  };

  const selectRace = async () => {
    if (raceSelected) {
      const raceEventComplete = await getRacecourseEventCompleteById(
        raceSelected.racecourse_event._id
      );
      const betTypesAvailable: BetTypeCombinationOptions[] =
        getBetTypesToLoadResults(raceSelected.number, raceEventComplete).map(
          (item) => {
            return {
              ...item,
              combination_runners: getRunnersForBetTypeCombination(
                raceSelected.number,
                item.bet_id,
                raceEventComplete
              ),
            };
          }
        );
      setBetTypesAvailable(betTypesAvailable);
      setMode(modes.setPositions);
      console.log("betTypesAvailable", betTypesAvailable);
    } else {
      dispatch(
        messageSystemPush({
          statusCode: 500,
          message: "No se ha seleccionado una carrera",
          error: "No se ha seleccionado una carrera",
        })
      );
    }
  };

  const handlePositionsRecieved = (
    data: { number: number; position: number }[]
  ) => {
    setPositions(data);
    setMode(modes.setResults);
  };

  const handleInputResultsRecieved = (data: BetResultItem[]) => {
    setMode(modes.confirmResults);
    setBetItemResults(data);
  };

  const backToSetResults = () => {
    setMode(modes.setResults);
  };

  const handleSendResults = async () => {
    try {
      console.log("handleSendResults", betItemResults);
      if(raceSelected) {
        const inputPayload = getResultsInputPayload(date.format('YYYY-MM-DD'), positions, betItemResults, raceSelected);
        const response = await createResults(inputPayload);

        cleanToSelectDate();
      }
    } catch (error: any) {
      dispatch(messageSystemPush({
        statusCode: error.statusCode || 500,
        message: error.message || 'Error al registrar resultados',
        error: error.error || 'Error al registrar resultados'
      }))
    }


  
  };

  return (
    <>
      <Card style={{ width: "100%" }} title="Resultados" extra>
        <Form layout="horizontal" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Fecha del Evento">
                <DatePicker
                  disabled={mode !== modes.selectDate}
                  format={dateFormat}
                  value={date}
                  onChange={handleDateChange}
                />
                {mode === modes.selectDate && (
                  <Button
                    onClick={() => selectDate()}
                    style={{ marginLeft: ".2em" }}
                    type="primary"
                    htmlType="button"
                  >
                    Seleccionar
                  </Button>
                )}
                {mode !== modes.selectDate && (
                  <Button
                    onClick={() => cleanToSelectDate()}
                    style={{ marginLeft: ".2em" }}
                    type="primary"
                    htmlType="button"
                  >
                    seleccionar Otro
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Selecciona Carrera">
                <Row gutter={8} align="middle">
                  <Col flex="auto">
                    <Select
                      disabled={mode !== modes.selectRace}
                      value={raceSelected?._id}
                      onChange={handleRacecourseRaceChange}
                    >
                      {racecourseEventRacePendingResults?.map((option) => (
                        <Select.Option key={option._id} value={option._id}>
                          {option.racecourse_event.racecourse.name} -{" "}
                          {option.number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    {mode === modes.selectRace && (
                      <Button
                        onClick={() => selectRace()}
                        style={{ marginLeft: ".2em" }}
                        type="primary"
                        htmlType="button"
                      >
                        Seleccionar
                      </Button>
                    )}
                    {mode !== modes.selectRace && mode !== modes.selectDate && (
                      <Button
                        onClick={() => cleanToSelectRace()}
                        style={{ marginLeft: ".2em" }}
                        type="primary"
                        htmlType="button"
                      >
                        seleccionar Otro
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {raceSelected?.runners && mode === modes.setPositions && (
          <PositionSetter
            dispatch={handlePositionsRecieved}
            runners={raceSelected?.runners.filter(item => item.scratched === false).map((item) => ({
              name: item.name,
              number: item.number,
            }))}
          />
        )}
        {mode === modes.setResults && (
          <>

            <TableSetResults
              positionSetter={positions}
              betTypeCombinationOptions={betTypesAvailable}
              betItemsResults={betItemResults}
              handleSendResults={handleInputResultsRecieved}
              cleanToSelectPositions={cleanToSelectPositions}
            />
          </>
        )}
        {mode === modes.confirmResults && raceSelected && (
          <>
            <Row  justify="space-between">
              <Col>
                <Button
                  type="default"
                  htmlType="button"
                  onClick={() => backToSetResults()}
                >
                  Editar Resultados
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => handleSendResults()}
                >
                  Registrar Resultados
                </Button>
              </Col>
            </Row>
            <ResultPreviewList
              betItemsResults={betItemResults}
              currentRace={raceSelected}
            />
          </>
        )}
      </Card>
    </>
  );
};
