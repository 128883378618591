import { RacecourseItem } from "../../services/racecourse.service";
import { RACECOURSE_SET_LIST, RACECOURSE_SET_ITEM, RACECOURSE_SET_LIST_ALL, RACECOURSE_UPDATE_ITEM_IN_LIST_ALL } from "../action-types";

export const racecourseSetList = (data:any[]) => ({
    type: RACECOURSE_SET_LIST,
    payload: { 
        data
    }
})



export const racecourseSetItem = (item: RacecourseItem) => ({
    type: RACECOURSE_SET_ITEM,
    payload: item
})
 
 
export const racecourseSetListAll = (data:any[]) => ({
    type: RACECOURSE_SET_LIST_ALL,
    payload: data
})



export const racecourseUpdateItemInListAll = (item: RacecourseItem) => ({
    type: RACECOURSE_UPDATE_ITEM_IN_LIST_ALL,
    payload: item
})


