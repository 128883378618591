import {
  Button,
  Card,
  Col,
  DatePicker,
  List,
  Row,
  Tabs,
  Form,
  InputNumber,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messageSystemPush } from "../redux/actions/message-system";
import { RootState } from "../redux/store";
import * as moment from "moment-timezone";
import { IRaceInput, IRaceInputConfig, IRacecourseEventInput, createRacecourseEvent } from "../services/racecourse-event-create.service";
import { CardCreateRace } from "../Components/RacecourseEventCreate/CardCreateRace";
import { BET_TYPES_CORE } from "../services/result.service";
import { getRacecourseListAll } from "../services/racecourse.service";
import ConfirmDialog from "../Components/ConfirmDialog";

type FormValues = {
  date: moment.Moment | null;
  raceNumber: number;
  racecourse: string | undefined;
};

export const RacecourseEventCreate = () => {

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [date, setDate] = useState<moment.Moment>(
    moment.tz("America/Mexico_City")
  );
  const dispatch = useDispatch();
  const dateFormat = "DD/MM/YYYY";

  const selectRaceNumbersOptions = Array.from({ length: 30 }, (_, i) => i + 1);

  const [racecourseOptions, setRacecourseOptions] = useState<
    { _id: string; name: string }[]
  >([]);

  useEffect(()=> {
    getRacecourseList();
  }, [])

  const getRacecourseList = async () => {
    const racecourseList = await getRacecourseListAll();
    setRacecourseOptions(racecourseList.sort((a,b) => a.name.localeCompare(b.name)));
  };

  const [racesForEvent, setRacesForEvent] = useState<IRaceInputConfig[]>([]);

  const [formValues, setFormValues] = useState<FormValues>({
    date: null,
    raceNumber: 1,
    racecourse: undefined,
  });

  const [allDone, setAllDone] = useState<boolean>(false);

  const [modeView, setModeView] = useState<"eventData" | "eventRace">(
    "eventData"
  );

  const handleDateChange = (value: moment.Moment | null) => {
    setFormValues({ ...formValues, date: value });
  };

  const handleNumberChange = (value: number) => {
    setFormValues({ ...formValues, raceNumber: value });
  };

  const handleRacecourseChange = (value: string) => {
    setFormValues({ ...formValues, racecourse: value });
  };

  
  const refreshAndSetRacesForEvent =(races: IRaceInputConfig[]) => {
    setRacesForEvent(races);
    let allDone = true;
    races.forEach((race) => {
      if  (race.done === false) {
        allDone = false;
      }
    });

    setAllDone(allDone);
  }


  const handleSubmit = () => {
    if (formValues.date === null) {
      dispatch(
        messageSystemPush({
          message: "Selecciona una fecha",
          type: "error",
          statusCode: 500,
        })
      );
      return;
    }

    if (formValues.racecourse === undefined) {
      dispatch(
        messageSystemPush({
          message: "Selecciona un hipodromo",
          type: "error",
          statusCode: 500,
        })
      );
      return;
    }

    setModeView("eventRace");
    let races: IRaceInputConfig[] = [];
    for (let i = 1; i <= formValues.raceNumber; i++) {
      races.push({
        number: i,
        runners: [],
        bet_types: BET_TYPES_CORE.filter(item => [10,20,30].includes(item.bet_id)),
        minutes_to_start: 0,
        post_time: +new Date(),
        done: false,
        
      });
    }
    refreshAndSetRacesForEvent(races);
  };

  const handleRaceChanges = (race: IRaceInputConfig) => {
    const newRaces = racesForEvent.map((raceForEvent) => {
      if(raceForEvent.number === race.number) {
        return race;
      }
      return raceForEvent;
    });
    refreshAndSetRacesForEvent(newRaces);
  };

  const confirmCreateEvent = () => {
    setShowConfirmDialog(true);
  }

  const handleCreateEvent = async () => {
    try {
          //dialog to confirm using antd
    if(!formValues.date) {
      dispatch(
        messageSystemPush({
          message: "Selecciona una fecha",
          type: "error",
          statusCode: 500,
        })
      );
      return;
    }

    if(!formValues.racecourse) {
      dispatch(
        messageSystemPush({
          message: "Selecciona un hipodromo",
          type: "error",
          statusCode: 500,
        })
      );
      return;
    }

    const eventPayload: IRacecourseEventInput = {
      date: formValues.date?.format('YYYY-MM-DD') || new Date().toISOString().split('T')[0],
      racecourse: formValues.racecourse,
      races: racesForEvent.map((race) => ({
        number: race.number,
        runners: race.runners,
        bet_types: race.bet_types,
        minutes_to_start: race.minutes_to_start ?? 0, //TODO pending
        post_time: race.post_time ?? +new Date(), //TODO pending
      })),
      }
      console.log(eventPayload);
      await createRacecourseEvent(eventPayload);
      setModeView("eventData");
      setFormValues({ date: null, raceNumber: 1, racecourse: undefined });
      setRacesForEvent([]);
    } catch (error: any) {
      dispatch(
        messageSystemPush({
          message: error?.message || 'Error al crear el evento',
          type: "error",
          statusCode: 500,
        })
      );
    } finally {

      setShowConfirmDialog(false);
    }

    }
  return (
    <>
      <ConfirmDialog
        title="Crear Evento"
        isVisible={showConfirmDialog}
        onConfirm={handleCreateEvent}
        onCancel={() => setShowConfirmDialog(false)}
        content="¿Estas seguro de crear el evento?"
      ></ConfirmDialog>
      <Content style={{ padding: ".3em" }}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ padding: ".3em" }}
          >
            {modeView === "eventData" && (
              <Card style={{ width: "100%" }} title="Crear Evento" extra >
                <Form layout="horizontal" onFinish={handleSubmit}>
                  <Form.Item label="Fecha del Evento">
                    <DatePicker
                      format={dateFormat}
                      value={formValues.date}
                      onChange={handleDateChange}
                    />
                  </Form.Item>

                  <Form.Item label="Selecciona Hipodromo">
                    <Select
                      value={formValues.racecourse}
                      onChange={handleRacecourseChange}
                    >
                      {racecourseOptions.map((option) => (
                        <Select.Option key={option._id} value={option._id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Numero de Carreras">
                    <Select
                      value={formValues.raceNumber}
                      onChange={handleNumberChange}
                    >
                      {selectRaceNumbersOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Siguiente
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}

            {modeView === "eventRace" && (
              <Card style={{ width: "100%" }} title="Carreras" extra={<Button onClick={() => confirmCreateEvent()} disabled={!allDone} type="primary">Crear Evento</Button>} >
                <Row gutter={16}>
                  {racesForEvent.map((item) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      key={item.number}
                      style={{ padding: ".3em" }}
                    >
                      <CardCreateRace dateSelected={date.toISOString()} dispatchRaceChange={handleRaceChanges} race={item} />
                    </Col>
                  ))}
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </Content>
    </>
  );
};
