import { RacecourseItem } from "../../services/racecourse.service"
import { RACECOURSE_SET_ITEM, RACECOURSE_SET_LIST, RACECOURSE_SET_LIST_ALL, RACECOURSE_UPDATE_ITEM_IN_LIST_ALL } from "../action-types"

const initialState = {
    racecourse_list_all: [],
    data: [],
    current: 1,
    pageSize: 20,
    total: 0
}


const RacecourseReducer= (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {

        case RACECOURSE_SET_LIST:
            const {data} = action.payload
            return {...state, data }

        case RACECOURSE_SET_ITEM:
            return {...state, racecourse_list_all: [...state.racecourse_list_all, action.payload]}

        case RACECOURSE_SET_LIST_ALL:
            return {...state, racecourse_list_all: action.payload}

        case RACECOURSE_UPDATE_ITEM_IN_LIST_ALL:
            const racecourse:RacecourseItem = action.payload
            const items = state.racecourse_list_all.map((item:any) => {
                if(racecourse._id === item._id) {
                    return {...racecourse}
                } else {
                    return item
                }
            })
            return {...state, racecourse_list_all: items}
        default:
            return state
        break;
    }
}


export default RacecourseReducer