import { IRacecourseEventComplete, IRacecourseEventCompleteRaceBettype } from "../services/interfaces/racecourse-event-race-complete";
import { BET_TYPES_CORE } from "../services/result.service";

export function getBetTypesToLoadResults(raceNumber: number, event: IRacecourseEventComplete) {
  let betTypes: {bet_id: number, name_front: string, name: string}[] = [];

  // Apuestas que solo dependen de la carrera actual.
  const singleRaceBets = ['Win', 'Place', 'Show', 'Exacta', 'Trifecta', 'Superfecta', 'Superhighfive'];
  
  // Añadir apuestas que solo dependen de la carrera actual.
  for (const betType of singleRaceBets) {
    const raceTarget = event.races.find(r => r.number === raceNumber);
    if (raceTarget?.bet_types.some(b => b.name === betType)) {
      betTypes.push(BET_TYPES_CORE.find(b => b.name === betType)!);
    }
  }

  // Considerar DOUBLE (raceNumber y raceNumber - 1).
  if (event.races.find(r => r.number === raceNumber -1)?.bet_types.some(b => b.name === 'Daily Double')) {
    betTypes.push(BET_TYPES_CORE.find(b => b.name === 'Daily Double')!);
  }
  // Considerar los PICKS.
  const pickBets = ['Pick 3', 'Pick 4', 'Pick 5', 'Pick 6', 'Pick 9'];

  for (const pick of pickBets) {
    const pickNumber = parseInt(pick.split(' ')[1], 10);
    const raceTarget = raceNumber - pickNumber + 1;
    if (event.races.find(r => r.number === raceTarget)?.bet_types.some(b => b.name === pick)) {
      betTypes.push(BET_TYPES_CORE.find(b => b.name === pick)!);
    }
  }

  return betTypes;
}
