import connector from './connector.service'
import { RaceInfoStatus } from './racecourse-race.service';

export interface IRacecourseEventInput {
  date: string; // YYYY-MM-DD
  racecourse: string;
  races: IRaceInput[];
}

export interface IRaceInput {
  number: number;
  post_time: number;
  minutes_to_start: number;
  bet_types: IBetTypeInput[];
  runners: IBetRunnerInput[];
}

export interface IBetTypeInput {
  bet_id: number;
  name_front: string;
  name: string;
}

export interface IBetRunnerInput {
  number: number;
  name: string;
  odds: string;
  scratched: boolean;
}

export interface IRaceInputConfig extends IRaceInput {
  done: boolean;
}

//TODO pending to improve
export const createRacecourseEvent = async (payload: IRacecourseEventInput):Promise<void> => {
  //update-status/:id/:status
  const racecourseEvent = await connector.post(`/racecourse-event/create-from-user`, payload);
}
